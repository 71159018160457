<template>
  <div class="no-content exact-center flex-col position-relative">
    <img
      class="image-layout mb-6"
      src="@/assets/icons/no-content.svg"
      alt="no-content"
    />
    <h1>No Data</h1>
  </div>
</template>

<script>
export default {
  name: "NoContent",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.no-content {
  h1 {
    font-size: 18px;
    color: $secondary-color;
  }
}
</style>
