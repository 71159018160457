import Vue from "vue";
import { _get, _put } from "../services/crudService";
import { API, SHIOK_LOGISTICS_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    requests: [],
    searchQuery: "",
    page: 0,
    limit: 10,
    totalRequest: 0,
    fromDate: "",
    toDate: "",
    type: "",
    statusFilter: "",
    delivertTypeFilter: "",
    isRequestFilter: false,
    merchantDataSet: [],
  },
  getters: {
    requests: ({ requests }) => requests,
    totalRequest: ({ totalRequest }) => totalRequest,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    isRequestFilter: ({ isRequestFilter }) => isRequestFilter,
    statusFilter: ({ statusFilter }) => statusFilter,
    delivertTypeFilter: ({ delivertTypeFilter }) => delivertTypeFilter,
    merchantDataSet: ({merchantDataSet}) => merchantDataSet
  },
  mutations: {
    setRequests(storeState, { requests, requestsCount }) {
      storeState.requests = requests;
      storeState.totalRequest = requestsCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setMerchant(storeState, payload){
      storeState.merchantDataSet = payload;
    },
    setFilterValues(storeState, payload) {
      if (payload !== null) {
        const { statusFilter, dateRange, type, delivertTypeFilter, merchantFilter } = payload;
        storeState.statusFilter = statusFilter ? statusFilter : "";
        storeState.merchantFilter = merchantFilter ? merchantFilter : '';
        storeState.fromDate = dateRange ? dateRange.fromDate : "";
        storeState.toDate = dateRange ? dateRange.toDate : "";
        storeState.type = type ? type : "";
        storeState.delivertTypeFilter = delivertTypeFilter
          ? delivertTypeFilter
          : "";
        storeState.isRequestFilter = true;
      } else {
        storeState.merchantFilter = "",
        storeState.statusFilter = "";
        storeState.fromDate = "";
        storeState.toDate = "";
        storeState.type = "";
        storeState.delivertTypeFilter = "";
        storeState.isRequestFilter = false;
      }
    },
  },
  actions: {
    async getRequests({ commit, state, rootState }) {
      const {
        page,
        limit,
        searchQuery,
        fromDate,
        toDate,
        statusFilter,
        merchantFilter,
        delivertTypeFilter,
        type,
      } = state;
      const { data } = await _get(
        `${API.REQUEST.FETCH}?page=${page}&dateType=${type}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&companyID=${rootState.logistics.authentication.loggedUser.company_id}&statusFilter=${statusFilter}&delivertTypeFilter=${delivertTypeFilter}&merchantID=${merchantFilter}`
      );
      commit("setRequests", data);
    },
    async updateRequest(storeState, payload) {
      await _put(`${API.REQUEST.UPDATE}/${payload.id}`, payload.data);
    },
    async assignDriver(storeState, payload) {
      await _put(
        `${API.REQUEST.UPDATE}/${payload.id}/assign-driver`,
        payload.data
      );
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setFilterValues({ commit }, payload) {
      commit("setFilterValues", payload);
    },
    async getMerchant({ commit }) {
      const { data } = await _get(`${API.REQUEST.FETCH_MERCHANT}`);
      commit("setMerchant", data);
    },
    async exportRequests({ state, rootState }) {
      const {
        page,
        limit,
        searchQuery,
        fromDate,
        toDate,
        type,
        statusFilter,
        delivertTypeFilter,
        merchantFilter,
      } = state;
      submitPostForm(
        `${SERVER_URL}/logistics${API.REQUEST.FETCH}/export/requests?page=${page}&companyID=${rootState.logistics.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&statusFilter=${statusFilter}&delivertTypeFilter=${delivertTypeFilter}&merchantID=${merchantFilter}`,
        "token",
        Vue.$cookies.get(SHIOK_LOGISTICS_TOKEN)
      );
    },
  },
};
