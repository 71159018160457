import { _get, _post } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    products: [],
    productsByCategory: [],
    categories: [],
    brands: [],
  },
  getters: {
    products: ({ products }) => products,
    productsByCategory: ({ productsByCategory }) => productsByCategory,
    categories: ({ categories }) => categories,
    brands: ({ brands }) => brands,
  },
  mutations: {
    setProductsByCategoryInProduct(storeState, { product }) {
      storeState.productsByCategory = product;
    },
    setProductsByCategory(storeState, { product }) {
      storeState.products = product;
    },
    setProductCategories(storeState, payload) {
      storeState.categories = payload.map((obj) => {
        return obj.product_category;
      });
    },
    setProductBrands(storeState, payload) {
      storeState.brands = payload.map((obj) => {
        return obj.product_brand;
      });
    },
  },
  actions: {
    async addProduct(storeState, payload) {
      const { data } = await _post(API.PRODUCT.CREATE, payload);
      const { productDetail } = data;
      productDetail.name= productDetail.product_name
      return productDetail;
    },
    async getProductCategories({ commit }, payload) {
      const { data } = await _get(
        `${API.PRODUCT.CATEGORY}?companyID=${payload}`
      );
      commit("setProductCategories", data.product);
    },
    async getProductBrands({ commit }, payload) {
      const { data } = await _get(
        `${API.PRODUCT.BRAND}?companyID=${payload}`
      );
      commit("setProductBrands", data.product);
    },
  },
};
