import Vue from "vue";
import VueRouter from "vue-router";
import MerchantRoutes from "../components/Merchant/router";
import AdminRoutes from "../components/Admin/router";
import LogisticRoutes from "../components/Logistics/router";
import AuthRoutes from "../components/Authorization/router";
import PageNotFound from "../components/PageNotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { path: "/auth/login/:type", params: { type: "admin" } },
  },
  {
    path: "/auth/login",
    redirect: { path: "/auth/login/:type", params: { type: "admin" } },
  },
  ...AdminRoutes,
  ...MerchantRoutes,
  ...AuthRoutes,
  ...LogisticRoutes,
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
