import Vue from "vue";
import { _get } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from '../../../services/ReusableServices/form-post-dom'
import { SERVER_URL } from '../../../../constants'

export default {
  namespaced: true,

  state: {
    customers: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalCustomers: 0,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    customers: ({ customers }) => customers,
    totalCustomers: ({ totalCustomers }) => totalCustomers,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setCustomers(storeState, { users, usersCount }) {
      storeState.customers = users;
      storeState.totalCustomers = usersCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getCustomers({ commit, state }) {
      const { page, searchQuery, fromDate, toDate, type, limit } = state;
      const { data } = await _get(
        `${API.CUSTOMERS.FETCH}?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&limit=${limit}`
      );
      commit("setCustomers", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportCustomers({ state }) {
      const { page, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.CUSTOMERS.FETCH}/export/customers?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        'token',
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      )
    },
  },
};
