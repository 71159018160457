<template>
  <div>
    <v-text-field
      :label="label"
      filled
      v-model="editValue"
      :type="inputType"
      :min="min"
      :rows="rows"
      :value="value"
      :error="error"
      :error-messages="errorMessages"
      :class="{ textAreaError: error, required: required }"
      v-on="debounce ? { input: onChangeBounce } : { input: onchange }"
      @keyup.enter="onEnter(editValue)"
      :disabled="disabled"
      @blur="doActionAtBlur(editValue)"
      :autofocus="autofocus"
    >
      <template v-if="icon" v-slot:prepend-inner>
        <img
          width="12"
          height="12"
          class="mt-1"
          src="@/assets/icons/search.svg"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  name: "TextArea",
  props: [
    "rows",
    "label",
    "required",
    "value",
    "error",
    "disabled",
    "inputType",
    "min",
    "errorMessages",
    "autofocus",
    "icon",
    "debounce",
  ],
  components: {},
  data() {
    return {
      editValue: "",
    };
  },
  methods: {
    onchange(value) {
      this.$emit("input", value);
    },
    onChangeBounce: debounce(function(value) {
      this.$emit("input", value);
    }, 800),
    doActionAtBlur(editValue) {
      this.$emit("blur", editValue);
    },
    onEnter(value) {
      this.$emit("onEnter", value);
    },
  },
  watch: {
    editValue() {
      this.$emit("change", this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  box-sizing: border-box;
  border-radius: 1px;
  height: 64px;
}
::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
