<template>
  <div class="search-container align-center header-slot">
    <img class="search-icon" width="16" height="16" src="@/assets/icons/search.svg" />
    <v-text-field class="search-input" placeholder="Search..." :value="value" @input="changeSearch" :autofocus="autofocus"/>
  </div>
</template>

<script>
import { debounce } from "debounce";

export default {
  name:'SearchInput',
  props:['value', 'autofocus'],
  methods:{
    changeSearch: debounce(function (searchTerm) {
      this.$emit("change", searchTerm);
    }, 800),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.search-container{
  display: flex;
  flex-direction: row;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  padding-left: 1rem;
  background: #FFFFFF;
  height: 40px;
}
.search-input{
  margin-left: 8px;

  &::placeholder{
    font-size: 12px;
    color: $secondary-color
  }
}
 .header-slot {
    font-size: 12px;
    ::v-deep .v-input__slot::before {
      border-style: none !important;
    }
    ::v-deep .v-text-field {
      padding-top: unset !important;
      margin-top: 10px !important;
      height: 40px !important;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
    ::v-deep .v-input__slot:after {
      border-style: none !important;
    }
  }
</style>