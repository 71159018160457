<template>
  <div class="more-options-container display-flex flex-col">
    <div class="more-options-item" @click="exportClicked">Export</div>
    <div class="divider"></div>
    <div
      class="more-options-item display-flex justfy-space-between align-center width-100"
    >
      <div>Active</div>
      <div class="mb-1 ml-6">
        <v-switch
          color="#FF003F"
          v-model="toggleValue"
          @change="activeToggle"
          hide-details
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreOptions",
  components: {},
  data() {
    return {
      toggleValue: false,
    };
  },
  methods: {
    exportClicked() {},
    activeToggle(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.v-small-dialog__content {
  width: 152px;
}
.more-options-container {
  width: 9.5rem;
  .more-options-item {
    padding: 0.875rem 1rem;
    cursor: pointer;
  }
  .divider {
    height: 1px;
    background: #e6e6e6;
  }
}
</style>
