<template>
  <div class="display-flex">
    <SelectField :key="codeKey" :value="code" :data-set="items" @change="onCodeChange" />
    <v-text-field
      :label="label"
      filled
      v-model="editValue"
      type="number"
      :min="min"
      :rows="rows"
      :value="value"
      :error="error"
      :error-messages="errorMessages"
      :class="{
        textAreaError: error,
        required: required,
        'v-input--is-focused': editValue,
      }"
      @input="onchange"
      :disabled="!code"
      @blur="doActionAtBlur(editValue)"
      :autofocus="autofocus"
    >
      <template v-slot:prepend-inner>
        <div class="currency">{{ code.symbol }}</div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { CURRENCY_SYMBOL } from "../../../constants";
export default {
  name: "PriceField",
  props: [
    "rows",
    "label",
    "required",
    "value",
    "error",
    "disabled",
    "inputType",
    "min",
    "errorMessages",
    "autofocus",
    "currencyCode",
    "currency",
  ],
  components: {},
  data() {
    return {
      editValue: "",
      code: {},
      codeKey: 0,
      items: [
        {
          symbol: CURRENCY_SYMBOL["SGD"],
          name: "SGD",
        },
        { symbol: CURRENCY_SYMBOL["INR"], name: "INR" },
        { symbol: CURRENCY_SYMBOL["USD"], name: "USD" },
      ],
    };
  },
  methods: {
    onchange(value) {
      this.$emit("input", value);
    },
    doActionAtBlur(editValue) {
      this.$emit("blur", editValue);
    },
    onCodeChange(value) {
      this.code = value;
      this.$emit("currency", value.name);
    },
    setDefaultCurreny() {
      const code = this.currency ? this.currency : "SGD";
      this.code = this.items.find((item) => item.name === code);
      this.codeKey += 1;
    },
  },
  watch: {
    editValue() {
      this.$emit("change", this.editValue);
    },
  },
  mounted() {
    this.setDefaultCurreny();
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.currency {
  color: #39c25f;
}

::v-deep .v-input__prepend-outer {
  margin-right: unset !important;
  margin-top: unset !important;
}
::v-deep .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  box-sizing: border-box;
  border-radius: 1px;
  height: 64px;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep .textAreaError.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep .textAreaError.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input--is-focused {
  .v-input__prepend-inner {
    margin-top: 35px !important;
  }
}
::v-deep .v-input__prepend-inner {
  margin-top: 22px !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-select__slot {
  input {
    padding-top: 10px !important;
  }
}
::v-deep .v-select {
  width: 90px !important;
  .v-input__icon {
    height: 40px !important;
  }
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
