<template>
  <div>
    <v-autocomplete
      v-model="editValue"
      :items="dataSet"
      dense
      filled
      :label="label"
      item-text="name"
      return-object
      @change="onSelectChange"
      :class="{ textAreaError: error, required: required }"
      :disabled="disabled"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title
              v-if="data.item.name"
              v-html="data.item.name"
            ></v-list-item-title>
            <v-list-item-title v-else>
              <div class="display-flex align-center">
                <img src="@/assets/icons/add-action.svg" />
                <div class="ml-2 action-class">{{ data.item.action }}</div>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "SelectField",
  props: ["dataSet", "label", "value", "disabled", "required", "error"],
  data() {
    return {
      editValue: "",
    };
  },
  methods: {
    onSelectChange(value) {
      this.$emit("change", value);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
  watch: {
    value() {
      this.editValue;
    },
  },
  components: {},
  created(){
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  border-radius: 1px;
  padding-bottom: 10px;
  min-height: 64px !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep .textAreaError.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: 64px !important;
}
::v-deep .textAreaError.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input input {
  max-height: unset !important;
}
.action-class {
  color: #ff003f;
  font-weight: normal;
}
</style>
