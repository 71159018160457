const { _get, _post, _put } = require('../services/crudService')
const { API } = require('../config/constants')
export default {
    namespaced: true,
    state: {
        logisticsRoles: [],
        logisticsRolePermision: []
    },
    getters: {
        logisticsRoles: ({ logisticsRoles }) => logisticsRoles,
        logisticsRolePermision: ({ logisticsRolePermision }) => logisticsRolePermision
    },
    mutations: {
        setLogisticsRoles(state, payload) {
            state.logisticsRoles = payload.map(item => {
                item.expandSelected = false
                item.actions = true
                return item
            })
        },
        setSelectedLogisticsRole(state, payload) {
            state.logisticsRolePermision = payload.permission
        }
    },
    actions: {
        //Actions to fetch, add, delete and update roles in logistics portal - settings
        async fetchLogisticsRoles({ commit, rootState }) {
            const { data } = await _get(`${API.SETTINGS.FETCH}?companyID=${rootState.logistics.authentication.loggedUser.company_id}`)
            commit('setLogisticsRoles', data.roles)
        },
        async fetchLogisticsRoleById({ commit }, id) {
            const { data } = await _get(`${API.SETTINGS.FETCH_BY_ID}/${id}`)
            commit('setSelectedLogisticsRole', data.role)
        },
        async createNewLogisticsRole({ rootState }, payload) {
            await _post(API.SETTINGS.CREATE, { ...payload, company_id: rootState.logistics.authentication.loggedUser.company_id })
        },
        async updatePermssion(state, payload) {
            await _put(`${API.SETTINGS.UPDATE_PERMISSION}/${payload.id}`, { allow: payload.allow })
        },
        async updateLogisticsRole(state, payload) {
            await _put(`${API.SETTINGS.UPDATE}/${payload.id}`, { name: payload.name })
        },
        async updateLogistic(state, payload) {
            await _put(`${API.SETTINGS.UPDATE_LOGISTIC}/${payload.id}`, payload.data)
        }
    },
}