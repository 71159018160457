const { _get, _post, _put } = require('../services/crudService')
const { API } = require('../config/constants')

export default {
    namespaced: true,
    state: {
        merchantRoles: [],
        merchantRolePermision: []
    },
    getters: {
        merchantRoles: ({ merchantRoles }) => merchantRoles,
        merchantRolePermision: ({ merchantRolePermision }) => merchantRolePermision
    },
    mutations: {
        setMerchantRoles(state, payload) {
            state.merchantRoles = payload.map(item => {
                item.expandSelected = false
                item.actions = true
                return item
            })
        },
        setSelectedMerchantRole(state, payload) {
            state.merchantRolePermision = payload.permission
        }
    },
    actions: {
        //Actions to fetch, add, delete and update roles in merchant portal - settings
        async fetchMerchantRoles({ commit, rootState }) {
            const { data } = await _get(`${API.SETTINGS.FETCH}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`)
            commit('setMerchantRoles', data.roles)
        },
        async fetchMerchantRoleById({ commit }, id) {
            const { data } = await _get(`${API.SETTINGS.FETCH_BY_ID}/${id}`)
            commit('setSelectedMerchantRole', data.role)
        },
        async createNewMerchantRole({ rootState }, payload) {
            await _post(API.SETTINGS.CREATE, { ...payload, company_id: rootState.merchant.authentication.loggedUser.company_id })
        },
        async updatePermssion(state, payload) {
            await _put(`${API.SETTINGS.UPDATE_PERMISSION}/${payload.id}`, { allow: payload.allow })
        },
        async updateMerchantRole(state, payload) {
            await _put(`${API.SETTINGS.UPDATE}/${payload.id}`, { name: payload.name })
        },
        async updateMerchant(state, payload) {
            await _put(`${API.SETTINGS.UPDATE_MERCHANT}/${payload.id}`, payload.data)
        }
    },
}