import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_LOGISTICS_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    sortingCenters: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalSortingCenter: 0,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    sortingCenters: ({ sortingCenters }) => sortingCenters,
    totalSortingCenter: ({ totalSortingCenter }) => totalSortingCenter,
    page: ({ page }) => page,
    sortingCenterPage: ({page}) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setSortingCenters(storeState, { sortingCenters, sortingCentersCount }) {
      storeState.sortingCenters = sortingCenters.map((obj) => {
        obj.sortingCenter_nameWithAddress = `${obj.sortingcenter_name}, ${obj.address}, ${obj.cityortown}`;
        obj.actions = true;
        return obj;
      });
      storeState.totalSortingCenter = sortingCentersCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getSortingCenters({ commit, state }, payload) {
      const { page, searchQuery, fromDate, toDate, limit } = state;
      const { data } = await _get(
        `${API.SORTING_CENTER.FETCH}?page=${page}&limit=${limit}&search=${searchQuery}&companyID=${payload.company_id}&fromDate=${fromDate}&toDate=${toDate}`
      );
      commit("setSortingCenters", data);
    },
    async addSortingCenter(storeState, payload) {
      await _post(API.SORTING_CENTER.CREATE, payload);
    },
    async updateSortingCenter(storeState, payload) {
      await _put(`${API.SORTING_CENTER.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteSortingCenter(storeState, payload) {
      await _delete(`${API.SORTING_CENTER.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportSortingCenters({ state, rootState }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/logistics${API.SORTING_CENTER.FETCH}/export/sortingCenter?page=${page}&companyID=${rootState.logistics.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_LOGISTICS_TOKEN)
      );
    },
  },
};
