import authentication from "./authentication";
import customers from "./customers";
import dashboard from "./dashboard";
import logistics from "./logistics";
import logisticUsers from "./logisticUsers";
import drivers from "./drivers";
import requests from "./requests";
import merchants from "./merchants";
import merchantUsers from "./merchantUsers";
import warehouse from "./warehouse";
import orders from "./orders";
import customerOrders from "./customerOrders";
import users from "./users";
import settings from "./settings";
import promotion from "./promotions";
import product from "./product";

export default {
  namespaced: true,

  state: {},
  getters: {},
  mutations: {},
  actions: {},

  // Nested Modules
  modules: {
    authentication,
    dashboard,
    users,
    settings,
    //Admin - Merchant Store
    merchants,
    merchantUsers,
    warehouse,
    orders,
    //Admin - Logistic Store
    logistics,
    logisticUsers,
    drivers,
    requests,
    //Admin - Customer Store
    customers,
    promotion,
    product,
    customerOrders,
  },
};
