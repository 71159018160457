export const SHIOK_ADMIN_TOKEN = "shiokAdminToken";

export const SHIOK_MERCHANT_TOKEN = "shiokMerchantToken";

export const SHIOK_LOGISTIC_TOKEN = "shiokLogisticToken";

export const ROUTES = {
  AUTH: "/auth",
  ADMIN_SIGNIN: "/auth/login/admin",
  MERCHANT_SIGNIN: "/auth/login/merchant",
  LOGISTIC_SIGNIN: "/auth/login/logistic",
  ADMIN_DASHBOARD: "/admin/dashboard",
  MERCHANT_DASHBOARD: "/merchant/dashboard",
  LOGISTIC_DASHBOARD: "/logistic/dashboard",
};
