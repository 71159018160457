import authentication from "./authentication";
import users from "./users";
import warehouse from "./warehouse";
import settings from "./settings";
import product from "./product";
import orders from "./orders";
import customers from "./customers";
import customerOrders from "./customerOrders";
import dashboard from "./dashboard";
import count from "./count";
import logs from "./logs";

export default {
  namespaced: true,

  state: {},
  getters: {},
  mutations: {},
  actions: {},

  // Nested Modules
  modules: {
    authentication,
    dashboard,
    users,
    warehouse,
    settings,
    product,
    orders,
    customers,
    customerOrders,
    count,
    logs,
  },
};
