import { _get } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    orders: [],
    searchQuery: "",
    ordersPage: 1,
    totalOrders: 0,
    ordersFromDate: "",
    ordersToDate: "",
    type: "",
  },
  getters: {
    orders: ({ orders }) => orders,
    totalOrders: ({ totalOrders }) => totalOrders,
    ordersPage: ({ ordersPage }) => ordersPage,
    ordersFromDate: ({ ordersFromDate }) => ordersFromDate,
    ordersToDate: ({ ordersToDate }) => ordersToDate,
  },
  mutations: {
    setOrders(storeState, { orders, ordersCount }) {
      storeState.orders = orders;
      storeState.totalOrders = Number(ordersCount);
    },
    setPage(storeState, payload) {
      storeState.ordersPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.ordersFromDate = dateRange ? dateRange.fromDate : "";
      storeState.ordersToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getOrders({ commit, state, rootState }, payload) {
      const {
        ordersPage,
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
      } = state;
      const { data } = await _get(
        `${API.CUSTOMERS.ORDERS.FETCH}/${rootState.merchant.authentication.loggedUser.company_id}?customerID=${payload.id}&page=${ordersPage}&search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&dateType=${type}&limit=5`
      );
      commit("setOrders", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
  },
};
