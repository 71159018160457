import { _get } from "../services/crudService";
import Vue from "vue";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    resourceCounts: {},
    orderDetails: {},
    orderLogs: [],
    logsiticsReportPage: 1,
    logisticsReport: [],
    totalLogisticsReport: 0,
    reportsPage: 1,
    reportCompanyCount: 0,
    reportCompanies: [],
    merchantReportsPage: 1,
    merchantReport: [],
    totalMerchantReport: 0,
    monthlyReport: [],
    deliveryReport: {
      cancelledOrders: 0,
      todaysDelivery: 0,
      totalDeliveries: 0,
      totalOrders: 0,
    },
    orderTrackDetails: {},
  },
  getters: {
    resourceCounts: ({ resourceCounts }) => resourceCounts,
    orderDetails: ({ orderDetails }) => orderDetails,
    orderLogs: ({ orderLogs }) => orderLogs,
    logsiticsReportPage: ({ logsiticsReportPage }) => logsiticsReportPage,
    logisticsReport: ({ logisticsReport }) => logisticsReport,
    merchantReportsPage: ({ merchantReportsPage }) => merchantReportsPage,
    merchantReport: ({ merchantReport }) => merchantReport,
    totalMerchantReport: ({ totalMerchantReport }) => totalMerchantReport,
    totalLogisticsReport: ({ totalLogisticsReport }) => totalLogisticsReport,
    reportsPage: ({ reportsPage }) => reportsPage,
    reportCompanyCount: ({ reportCompanyCount }) => reportCompanyCount,
    reportCompanies: ({ reportCompanies }) => reportCompanies,
    monthlyReport: ({ monthlyReport }) => monthlyReport,
    deliveryReport: ({ deliveryReport }) => deliveryReport,
    orderTrackDetails: ({ orderTrackDetails }) => orderTrackDetails,
  },
  mutations: {
    setResourceCounts(storeState, payload) {
      storeState.resourceCounts = payload;
    },
    setOrderTrackingData(storeState, payload) {
      let logObj = {};
      payload?.order_log?.map((log) => {
        logObj = { ...logObj, [log.status]: log };
      });
      storeState.orderDetails = payload.order;
      storeState.orderLogs = {
        items: logObj,
        count: Object.keys(logObj).length,
      };
    },
    setLogisiticsReportPage(storeState, payload) {
      storeState.logsiticsReportPage = payload;
    },
    setLogisticsReport(storeState, { companies, companiesCount }) {
      storeState.logisticsReport = companies;
      storeState.totalLogisticsReport = companiesCount;
    },
    setMerchantReportsPage(storeState, payload) {
      storeState.merchantReportsPage = payload;
    },
    setMerchantReport(storeState, { companies, companiesCount }) {
      storeState.merchantReport = companies;
      storeState.totalMerchantReport = companiesCount;
    },
    setReportsPage(storeState, payload) {
      storeState.reportsPage = payload;
    },
    setReportCompanyCount(storeState, payload) {
      storeState.reportCompanyCount = payload.companiesCount;
      storeState.reportCompanies = payload.companies.map((obj) => {
        obj.type = "Merchant";
        return obj;
      });
    },
    setMonthlyReport(storeState, payload) {
      storeState.monthlyReport = payload.map((obj) => {
        return obj.count;
      });
    },
    setDeliveryReport(storeState, payload) {
      storeState.deliveryReport = payload;
      const count =
        storeState.totalOrders -
        (storeState.totalDeliveries + storeState.cancelledOrders);
      storeState.deliveryReport.pending = count > 0 ? count : 0;
    },
    setOrderTrackDetails(storeState, payload) {
      storeState.orderTrackDetails = payload;
    },
    setOrderTrackingDataEmpty(storeState) {
      storeState.orderDetails = {};
      storeState.orderLogs = {};
    },
  },
  actions: {
    async getResourceCounts({ commit }) {
      const { data } = await _get(`${API.DASHBOARD.FETCH_COUNT}`);
      commit("setResourceCounts", data);
    },
    async getOrderTrackingData({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_ORDER_TRACKING}/${payload}`
      );
      commit("setOrderTrackingData", data);
    },
    async getLogisticsReport({ commit, state }, payload) {
      const { logsiticsReportPage } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_LOGISTICS_REPORT}?page=${logsiticsReportPage}&limit=5&month=${payload.month}&year=${payload.year}`
      );
      commit("setLogisticsReport", data);
    },
    setLogisiticsReportPage({ commit }, payload) {
      commit("setLogisiticsReportPage", payload);
    },
    async getMerchantReports({ commit, state }, payload) {
      const { merchantReportsPage } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_MERCHANT_REPORT}?page=${merchantReportsPage}&limit=5&month=${payload.month}&year=${payload.year}`
      );
      commit("setMerchantReport", data);
    },
    async exportReport(storeState, payload) {
      const { type, month, year } = payload;
      submitPostForm(
        `${SERVER_URL}/admin${API.DASHBOARD.EXPORT_REPORT}?type=${type}&month=${month}&year=${year}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
    exportMerchantById(storeState, payload) {
      submitPostForm(
        `${SERVER_URL}/admin${API.DASHBOARD.EXPORT_MERCHANT}?merchantId=${payload}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
 exportLogisticById(storeState, payload) {
      submitPostForm(
        `${SERVER_URL}/admin${API.DASHBOARD.EXPORT_LOGISTIC}?logisticId=${payload}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
    setMerchantReportsPage({ commit }, payload) {
      commit("setMerchantReportsPage", payload);
    },
    setReportsPage({ commit }, payload) {
      commit("setReportsPage", payload);
    },
    async fetchMerchantCustomerReport({ state, commit }, payload) {
      const { reportsPage } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_MERCHANT_CUSTOMER}?page=${reportsPage}&limit=5&month=${payload.month}&year=${payload.year}`
      );
      commit("setReportCompanyCount", data);
    },
    async fetchLogisticsCustomerReport({ state, commit }, payload) {
      const { reportsPage } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_LOGISTICS_CUSTOMER}?page=${reportsPage}&limit=5&month=${payload.month}&year=${payload.year}`
      );
      commit("setReportCompanyCount", data);
    },
    async fetchMonthlyReport({ commit }) {
      const { data } = await _get(`${API.DASHBOARD.FETCH_MONTHLY_REPORT}`);
      commit("setMonthlyReport", data);
    },
    async fetchDeliveryReport({ commit }) {
      const { data } = await _get(`${API.DASHBOARD.FETCH_DELIVERY_REPORT}`);
      commit("setDeliveryReport", data);
    },
    async trackOrder({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.ORDER_TRACK}?trackingID=${payload}`
      );
      commit("setOrderTrackDetails", data[0]);
    },
    setOrderTrackingDataEmpty({ commit }) {
      commit("setOrderTrackingDataEmpty");
    },
  },
};
