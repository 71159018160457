import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    adminMerchants: [],
    adminMerchantCount: 0,
    searchQuery: "",
    adminMerchantPage: 0,
    adminMerchantLimit: 10,
    fromDate: "",
    toDate: "",
    type: "",
    sortType: "",
    isMerchantFilter: false,
  },
  getters: {
    adminMerchantCount: ({ adminMerchantCount }) => adminMerchantCount,
    adminMerchants: ({ adminMerchants }) => adminMerchants,
    adminMerchantPage: ({ adminMerchantPage }) => adminMerchantPage,
    adminMerchantLimit: ({ adminMerchantLimit }) => adminMerchantLimit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    sortType: ({ sortType }) => sortType,
    type: ({ type }) => type,
    isMerchantFilter: ({ isMerchantFilter }) => isMerchantFilter,
  },
  mutations: {
    setMerchants(state, payload) {
      state.adminMerchantCount = payload.companiesCount;
      state.adminMerchants = payload.companies.map((obj) => {
        obj.actions = true;
        obj.warehouseSelected = false;
        obj.userSelected = false;
        obj.ordersSelected = false;
        obj.selected = false;
        return obj;
      });
    },
    setPage(storeState, payload) {
      storeState.adminMerchantPage = payload;
    },
    setLimit(storeState, payload) {
      storeState.adminMerchantLimit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setFilterValues(storeState, payload) {
      if (payload !== null) {
        const {
          dateRange,
          sortType,
          type,
        } = payload;
        storeState.fromDate = dateRange ? dateRange.fromDate : "";
        storeState.toDate = dateRange ? dateRange.toDate : "";
        storeState.sortType = sortType ? sortType : "";
        storeState.type = type ? type : "";
        storeState.isMerchantFilter = true;
      } else {
        storeState.fromDate = "";
        storeState.toDate = "";
        storeState.sortType = "";
        storeState.type = "";
        storeState.isMerchantFilter = false;
      }
    },
  },
  actions: {
    async addMerchant(storeState, payload) {
      await _post(API.MERCHANT.CREATE, payload);
    },
    async updateMerchant(storeState, payload) {
      await _put(`${API.MERCHANT.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteMerchant(storeState, payload) {
      await _delete(`${API.MERCHANT.DELETE}/${payload.id}`);
    },
    async setActiveMerchant(storeState, payload) {
      await _put(`${API.MERCHANT.ACTIVE}/${payload.id}`);
    },
    async getMerchants({ commit, state }) {
      const {
        adminMerchantPage,
        adminMerchantLimit,
        searchQuery,
        fromDate,
        toDate,
        type,
        sortType,
      } = state;
      const { data } = await _get(
        `${API.MERCHANT.FETCH}?page=${adminMerchantPage}&limit=${adminMerchantLimit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&sortType=${sortType}`
      );
      commit("setMerchants", data);
    },
    async getMerchantById() {
      await _get(`${API.MERCHANT.FETCH}/1`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    setFilterValues({ commit }, payload) {
      commit("setFilterValues", payload);
    },
    async exportMerchants({ state }) {
      const {
        adminMerchantPage,
        adminMerchantLimit,
        searchQuery,
        fromDate,
        toDate,
        type,
      } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.MERCHANT.FETCH}/export/merchants?page=${adminMerchantPage}&limit=${adminMerchantLimit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
  },
};
