<template>
  <div class="action-button-container exact-center cursor-pointer" @click="onClick" :style="{width: width}"  :class="{'active' : active, className : className }">
  <div class="icon mb-1"><img :src="active ? require(`@/assets/icons/${svgPath}-active.svg`) : require(`@/assets/icons/${svgPath}.svg`)" /></div> 
  <div class="label ml-1">{{label}}</div>
  <div class="count">{{count}}</div> 
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  props: ['active', 'label', 'icon', 'count', 'width', "className" ],
  data(){
      return{
          svgPath: '',
      }
  },
  methods:{
      onClick(){
          this.$emit('click');
      },
  },
  created(){
      this.svgPath = this.icon;
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.action-button-container{
    min-width: 130px;
    background: $white-color;
    border: 1px solid $secondary-color;
    border-radius: 0.25rem;
    padding: 0.563rem 4.375rem;
    .label{
        color: $secondary-color;
        font-weight: 500;
        font-size: 12px;
        margin-right: 0.375rem;
    }
    .icon{
        margin-right: 0.375rem;
        width: 1rem;
    }
    .count{
        font-weight: 500;
        font-size: 12px;
        color: $primary-color;
    }
}
.active{
    background: $secondary-color !important;
    color: $white-color;
    .label{
        color: $white-color;
    }
    .count{
        color: #FFF500;
    }
}
</style>
