import { _get } from "../services/crudService";
import { API } from "../config/constants";
import moment from "moment";

export default {
  namespaced: true,

  state: {
    resourceCounts: {},
    customersCount: {
      todayCustomersCount: 0,
      customersCount: 0,
    },
    orderDetails: {},
    orderLogs: [],
    orderGraphData: {
      current: [],
      last: [],
    },
    orderSummary: {},
    fromDate: "",
    toDate: "",
    orderTrackDetails: {},
  },
  getters: {
    resourceCounts: ({ resourceCounts }) => resourceCounts,
    customersCount: ({ customersCount }) => customersCount,
    orderDetails: ({ orderDetails }) => orderDetails,
    orderLogs: ({ orderLogs }) => orderLogs,
    orderGraphData: ({ orderGraphData }) => orderGraphData,
    orderSummary: ({ orderSummary }) => orderSummary,
    orderTrackDetails: ({ orderTrackDetails }) => orderTrackDetails,
  },
  mutations: {
    setResourceCounts(storeState, payload) {
      storeState.resourceCounts = payload;
    },
    setCustomersCounts(storeState, payload) {
      storeState.customersCount = payload;
    },
    setOrderTrackingData(storeState, payload) {
      if (payload && payload.order_log) {
        if (payload.order_log.length)
          payload.order_log[
            payload.order_log.length - 1
          ].isCurrentStatus = true;
      }
      let logObj = {};
      payload?.order_log?.map((log) => {
        logObj = { ...logObj, [log.status]: log };
      });
      storeState.orderDetails = payload.order;
      storeState.orderLogs = {
        items: logObj,
        count: Object.keys(logObj).length,
      };
    },
    setOrderGraphData(storeState, payload) {
      payload.currentWeekCount.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      payload.lastWeekCount.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      storeState.orderGraphData.current = payload.currentWeekCount.map(
        (obj) => {
          return Number(obj.count);
        }
      );
      storeState.orderGraphData.last = payload.lastWeekCount.map((obj) => {
        return Number(obj.count);
      });
    },
    setOrderSummary(storeState, payload) {
      storeState.orderSummary = payload;
      payload.dateWiseGraph.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      storeState.orderSummary.xAxis = payload.dateWiseGraph.map((obj) => {
        return moment(obj.date_trunc).format("DD");
      });
      storeState.orderSummary.yAxis = payload.dateWiseGraph.map((obj) => {
        return Number(obj.count);
      });
    },
    setDate(storeState, { fromDate, toDate }) {
      storeState.fromDate = fromDate;
      storeState.toDate = toDate;
    },
    setOrderTrackDetails(storeState, payload) {
      storeState.orderTrackDetails = payload;
    },
    setOrderTrackingDataEmpty({ commit }) {
      commit("setOrderTrackingDataEmpty");
    },
  },
  actions: {
    async getResourceCounts({ commit, rootState }) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_COUNT}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
      );
      commit("setResourceCounts", data);
    },

    async getCustomersCount({ commit, rootState }) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_COUNT_CUSTOMER}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
      );
      commit("setCustomersCounts", data);
    },

    async getOrderTrackingData({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_ORDER_TRACKING}/${payload}`
      );
      commit("setOrderTrackingData", data);
    },

    async getOrderGraphData({ commit }) {
      const { data } = await _get(`${API.DASHBOARD.FETCH_ORDER_GRAPH}`);
      commit("setOrderGraphData", data);
    },

    async getOrderSummary({ commit, state }) {
      const { fromDate, toDate } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_ORDER_SUMMARY}?fromDate=${fromDate}&toDate=${toDate}`
      );
      commit("setOrderSummary", data);
    },
    setDate({ commit }, payload) {
      commit("setDate", payload);
    },
    async trackOrder({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.ORDER_TRACK}?trackingID=${payload}`
      );
      commit("setOrderTrackDetails", data[0]);
    },
    setOrderTrackingDataEmpty(storeState) {
      storeState.orderDetails = {};
      storeState.orderLogs = {};
    },
  },
};
