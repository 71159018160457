import authentication from "./authentication";
import dashboard from "./dashboard";
import users from "./users";
import settings from "./settings";
import driver from "./driver";
import sortingCenter from "./sortingCenter";
import requests from "./requests";
import count from "./count"
import assignments from "./assignments";

export default {
  namespaced: true,

  state: {},
  getters: {},
  mutations: {},
  actions: {},

  // Nested Modules
  modules: {
    authentication,
    dashboard,
    users,
    settings,
    driver,
    sortingCenter,
    requests,
    count,
    assignments,
  },
};
