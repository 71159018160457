export const API = {
  AUTHENTICATION: {
    FORGOT_PASSWORD: "auth/forgot-password",
    RESET_PASSWORD: "auth/reset-password",
    SIGNIN: "auth/signin",
    CHANGE_PASSWORD: "users/profile/change-password",
  },
  DASHBOARD: {
    FETCH_COUNT: "dashboard/count",
    FETCH_ORDER_TRACKING: "/order-tracking",
    FETCH_DELIVERY_TYPE: "dashboard/delivery-type",
    FETCH_WEEK_ORDER_DATA: "dashboard/week-orders",
    FETCH_ORDER_SUMMARY: "dashboard/order-summary",
    ORDER_TRACK: "dashboard/tracking-details",
  },
  WAREHOUSE: {
    FETCH: "/warehouse",
    CREATE: "/warehouse",
    DELETE: "/warehouse",
    UPDATE: "/warehouse",
  },
  SETTINGS: {
    FETCH: "/roles/all",
    FETCH_BY_ID: "/roles",
    CREATE: "/roles",
    DELETE: "/roles",
    UPDATE: "/roles",
    UPDATE_PERMISSION: "/permissions",
    UPDATE_LOGISTIC: "/users/company/edit-details"
  },
  USERS: {
    FETCH: "/users",
    CREATE: "/users",
    DELETE: "/users",
    UPDATE: "/users",
    ACTIVE: "/users/make-active",
    FEEDBACK: "/users/send-feedback",
  },
  COMPANY_LOGO: {
    UPDATE: "/users/logistic-photo",
  },
  DRIVER: {
    FETCH: "/drivers",
    CREATE: "/drivers",
    DELETE: "/drivers",
    UPDATE: "/drivers",
    FETCH_DELIVERY: "/drivers/request",
  },
  SORTING_CENTER: {
    FETCH: "/sortingCenter",
    CREATE: "/sortingCenter",
    DELETE: "/sortingCenter",
    UPDATE: "/sortingCenter",
  },
  REQUEST: {
    FETCH: "/requests",
    UPDATE: "/requests",
    FETCH_MERCHANT: "/requests/merchant/dropdown",
  },
  COUNT: {
    FETCH: "/count",
  },
  ASSIGNMENTS: {
    FETCH: "/assignments",
  },
};

export const SHIOK_LOGISTICS_TOKEN = "shiokLogisticsToken";

export const ROUTES = {
  AUTH: "/auth",
  SIGNIN: "/auth/login/logistic",
  DASHBOARD: "/logistics/dashboard",
};
