import ActionButton from "@/components/common/ActionButton.vue";
import Avatar from "@/components/common/Avatar.vue";
import Button from "@/components/common/Button.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import HeaderDatePicker from "@/components/common/HeaderDatePicker.vue";
import ProfileImagePicker from "@/components/common/ProfileImagePicker.vue";
import ImageUpload from "@/components/common/ImageUpload.vue";
import SearchInput from "@/components/common/SearchInput.vue";
import SelectField from "@/components/common/SelectField.vue";
import TextArea from "@/components/common/TextArea.vue";
import TextField from "@/components/common/TextField.vue";
import Spinner from "@/components/common/Spinner.vue";
import CommonToolbar from "@/components/common/CommonToolbar";
import ConfirmationDialog from "@/components/common/ConfirmationDialog";
import SubHeader from "@/components/common/SubHeader";
import ActionSelection from "@/components/common/ActionSelection";
import DialCode from "@/components/common/DialCode";
import MoreOptions from "@/components/common/MoreOptions";
import InnerMoreOptions from "@/components/common/InnerMoreOptions";
import NoContent from "@/components/common/NoContent";
import Notification from "@/components/common/Notification";
import DateFilter from "@/components/common/DateFilter";
import DateSelectField from "@/components/common/DateSelectField";
import Combobox from "@/components/common/Combobox";
import PriceField from "@/components/common/PriceField";
import SkeletonLoader from "@/components/common/SkeletonLoader";
import NoAccess from "@/components/common/NoAccess";

export default {
  ActionButton,
  Avatar,
  Button,
  DatePicker,
  HeaderDatePicker,
  ImageUpload,
  ProfileImagePicker,
  SearchInput,
  SelectField,
  TextArea,
  TextField,
  Spinner,
  CommonToolbar,
  ConfirmationDialog,
  DialCode,
  SubHeader,
  ActionSelection,
  MoreOptions,
  InnerMoreOptions,
  NoContent,
  Notification,
  DateFilter,
  DateSelectField,
  Combobox,
  PriceField,
  SkeletonLoader,
  NoAccess,
};
