import Vue from "vue";
import { API, SHIOK_MERCHANT_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";
import { _get, _put } from "../services/crudService";

export default {
  namespaced: true,

  state: {
    customers: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalCustomers: 0,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    customers: ({ customers }) => customers,
    totalCustomers: ({ totalCustomers }) => totalCustomers,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setCustomers(storeState, { MerchantCustomers, MerchantCustomersCount }) {
      storeState.customers = MerchantCustomers;
      storeState.totalCustomers = Number(MerchantCustomersCount.count);
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getCustomers({ commit, state, rootState }) {
      const { page, searchQuery, fromDate, toDate, type } = state;
      const { data } = await _get(
        `${API.CUSTOMERS.FETCH}/${rootState.merchant.authentication.loggedUser.company_id}?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setCustomers", data);
    },
    async getOrderCustomers({ commit, state, rootState }) {
      const { page, searchQuery, fromDate, toDate, type } = state;
      const { data } = await _get(
        `${API.CUSTOMERS.ORDERS_FETCH}/${rootState.merchant.authentication.loggedUser.company_id}?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setCustomers", data);
    },
    async updateCustomer(storeState, payload) {
      await _put(`${API.CUSTOMERS.UPDATE}/${payload.id}`, payload.data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportCustomers({ state, rootState }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/merchant${API.CUSTOMERS.FETCH}/export/customers?page=${page}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      );
    },
  },
};
