<template>
  <div class="sub-header-container display-flex mt-4">
    <div
      v-for="item of navItems"
      :key="item.id"
      class="sub-nav-item cursor-pointer display-flex align-center position-relative mr-8"
      :class="{ 'active': item.isActive }"
      @click="itemSelected(item)"
    >
      <img
        :src="
          item.isActive
            ? require(`@/assets/icons/${item.activeIcon}.svg`)
            : require(`@/assets/icons/${item.icon}.svg`)
        "
        alt="sub-nav-icon"
      />
      <h1 class="nav-title ml-2">{{ item.title }}</h1>
      <div class="count ml-2 exact-center">
        <h1>{{ item.count }}</h1>
      </div>
      <div class="active-marker position-absolute"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: ["items"],
  components: {},
  data() {
    return {
      navItems: [],
    };
  },
  methods: {
    itemSelected(item) {
      this.navItems.map((nav) => {
        if (nav.route === item.route) {
          this.$router.push(nav.route).catch(() => {});
        }
      });
    },
    setActive() {
      this.navItems.map((menu) => {
        if (menu.route === this.$route.path) {
          menu.isActive = true;
        } else {
          menu.isActive = false;
        }
      });
    },
  },
  watch: {
    $route() {
      this.setActive();
    },
  },
  created() {
    this.navItems = this.items;
    this.setActive();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.sub-header-container {
  padding: 1rem 4.625rem;
  .sub-nav-item {
    .nav-title {
      font-weight: 500;
      font-size: 0.75rem;
      color: #999DA0;;
    }
  }
  .count {
    background: #e4e4e4;
    padding: 0.2rem;
    border-radius: 0.188rem;
    h1 {
      color: #999da0;
      font-weight: 600;
      font-size: 0.75rem;
    }
    .active-marker {
      display: none;
    }
  }
  .active {
   .nav-title {
      color: $secondary-color;
    }
    .active-marker {
      display: block;
      bottom: -1rem;
      height: 1px;
      width: 100%;
      background: $primary-color;
    }
    .count {
      background: #ffdee0;
      color: $primary-color;
    }
  }
}
</style>
