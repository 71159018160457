<template>
  <div>
    <v-autocomplete
      v-model="editValue"
      :items="dataSet"
      dense
      filled
      :label="label"
      :search-input.sync="searchInput"
      :item-text="itemText ? itemText : 'name'"
      return-object
      @change="onSelectChange"
      :class="{ textAreaError: error, required: required }"
      :disabled="disabled"
      :multiple="multiple"
      :chips="multiple"
      :small-chips="multiple"
      :loading="loading"
      @focus="focus"
    >
      <template v-slot:append-item>
        <div v-if="isInfinteScroll" v-intersect="endIntersect" />
      </template>
      <template v-if="multiple" v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <v-chip v-if="index === 1">
          <span>{{ item.name }}</span>
        </v-chip>
        <span v-if="index === 2" class="grey--text text-caption">
          (+{{ value.length - 2 }} others)
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  name: "SelectField",
  props: [
    "dataSet",
    "label",
    "value",
    "disabled",
    "required",
    "error",
    "multiple",
    "itemText",
    "loading",
    "isInfinteScroll",
  ],
  data() {
    return {
      editValue: "",
      searchInput: null,
    };
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit("onScroll");
      }
    },
    onSelectChange(value) {
      this.$emit("change", value);
    },
    focus() {
      this.$emit("focus");
    },
  },
  mounted() {
    this.editValue = this.value;
  },
  watch: {
    value() {
      this.editValue;
    },
    searchInput: debounce(function() {
      this.$emit("onSearch", this.searchInput);
    }, 200),
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  border-radius: 1px;
  padding-bottom: 10px;
  min-height: 64px !important;
}
::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: 64px !important;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input input {
  max-height: unset !important;
}
::v-deep .v-chip__content {
  span {
    width: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
