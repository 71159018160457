const LogisticComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../"));
    },
    "logistic"
  );
};

const LogisticDashboardComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../Dashboard"));
    },
    "logistic"
  );
};

const LogisticRequestComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../Requests"));
    },
    "logistic"
  );
};

const LogisticDriverComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../Drivers"));
    },
    "logistic"
  );
};

const LogisticSortingCenterComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../SortingCenter"));
    },
    "logistic"
  );
};

const LogisticUsersComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../Users"));
    },
    "logistic"
  );
};

const LogisticSettingsComponent = (resolve) => {
  require.ensure(
    ["../"],
    () => {
      resolve(require("../Settings"));
    },
    "logistic"
  );
};

export const LogisticRoutes = [
  {
    path: "/logistics",
    component: LogisticComponent,
    children: [
      {
        path: "/",
        name: "LogisticDashboard",
        component: LogisticDashboardComponent,
      },
      {
        path: "dashboard",
        name: "Logistic Dashboard",
        component: LogisticDashboardComponent,
      },
      {
        path: "requests",
        name: "Logistic Request",
        component: LogisticRequestComponent,
      },
      {
        path: "drivers",
        name: "Logistic Drivers",
        component: LogisticDriverComponent,
      },
      {
        path: "sorting-center",
        name: "Logistic Sorting Center",
        component: LogisticSortingCenterComponent,
      },
      {
        path: "users",
        name: "Logistic Users",
        component: LogisticUsersComponent,
      },
      {
        path: "settings",
        name: "Logistic Settings",
        component: LogisticSettingsComponent,
      },
    ],
  },
];

export default LogisticRoutes;
