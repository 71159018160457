<template>
  <div>
    <v-combobox
      v-model="select"
      :items="items"
      @change="onChange"
      @focus="onFocus"
      :item-text="itemText"
      :label="label"
      :class="{ textAreaError: error, required: required }"
      dense
      filled
      return-object
      :loading="loading"
      :search-input.sync="searchInput"
    >
      <template v-slot:prepend-inner>
        <img
          v-if="icon"
          width="12"
          height="12"
          class="margin-top-2 mr-2"
          src="@/assets/icons/search.svg"
        />
      </template>
      <template v-slot:append-item>
        <div v-if="isInfinteScroll" v-intersect="endIntersect" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  name: "ComboBox",
  props: [
    "icon",
    "label",
    "error",
    "required",
    "value",
    "items",
    "itemText",
    "isInfinteScroll",
    "loading",
  ],
  data() {
    return {
      select: "",
      searchInput: null,
    };
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit("onScroll");
      }
    },
    onChange(value) {
      this.$emit("onChange", value);
    },
    onFocus() {
      this.$emit("focus");
    },
  },
  created() {
    this.select = this.value;
  },
  watch: {
    searchInput: debounce(function () {
      this.$emit("onSearch", this.searchInput);
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  border-radius: 1px;
  padding-bottom: 10px;
  min-height: 64px !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep .textAreaError.v-text-field--filled > .v-input__control > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: 64px !important;
}
::v-deep .textAreaError.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}
.margin-top-2 {
  margin-top: 5.8px;
}
::v-deep .v-input input {
  max-height: unset !important;
}
</style>
