/* eslint-enable operator-linebreak */
const COLORS = [
    '#42A5F5',
    '#26A69A',
    '#EC407A',
    '#8D6E63',
    '#66BB6A',
    '#FFA726',
    '#AB47BC',
  ]
  
  module.exports = {
    fetchColor: (nameObj) => {
      if (nameObj.name) {
        const firstLetter = nameObj.name.toUpperCase().charAt(0)
        if (
          firstLetter === 'A' || firstLetter === 'G' || firstLetter === 'M' || firstLetter === 'S' || firstLetter === 'Y'
        ) {
          return COLORS[0]
        } if (
          firstLetter === 'B' ||firstLetter === 'H' ||firstLetter === 'N' ||firstLetter === 'T' ||firstLetter === 'Z'
        ) {
          return COLORS[1]
        } if (
          firstLetter === 'C' ||firstLetter === 'I' ||firstLetter === 'O' ||firstLetter === 'U'
        ) {
          return COLORS[2]
        } if (
          firstLetter === 'D' ||firstLetter === 'J' || firstLetter === 'P' ||firstLetter === 'V'
        ) {
          return COLORS[3]
        } if (
          firstLetter === 'E' ||firstLetter === 'K' ||firstLetter === 'Q' ||firstLetter === 'W'
        ) {
          return COLORS[4]
        } if (
          firstLetter === 'F' ||firstLetter === 'L' ||firstLetter === 'R' ||firstLetter === 'X'
        ) {
          return COLORS[5]
        }
      } if (nameObj.count > 0) {
        return COLORS[6]
      }
      return COLORS[0]
    },
  }
  