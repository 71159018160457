<template>
  <div class="more-options-container display-flex flex-col">
    <div
      class="
        more-options-item
        display-flex
        justfy-space-between
        align-center
        width-100
      "
      @click="editClicked"
    >
      <img src="@/assets/icons/edit.svg" alt="edit" />
      <div class="ml-4">Edit</div>
    </div>
    <div class="divider"></div>
    <div
      v-if="!isActiveInactive"
      class="
        more-options-item
        display-flex
        justfy-space-between
        align-center
        width-100
      "
      @click="deleteClicked"
    >
      <img src="@/assets/icons/more-delete.svg" alt="more-delete" />
      <div class="ml-4">Delete</div>
    </div>
    <div
      class="more-options-item display-flex justfy-space-between align-center width-100"
      v-else
    >
      <div class="mb-1">
        <v-switch
          color="#FF003F"
          v-model="toggleValue"
          @change="activeToggle"
          hide-details
        ></v-switch>
      </div>
      <div v-if="toggleValue">Active</div>
      <div v-else>InActive</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerMoreOptions",
  components: {},
  props: ["isActiveInactive", "active"],
  data() {
    return {
      toggleValue: false,
    };
  },
  methods: {
    editClicked() {
      this.$emit("editClicked");
    },
    deleteClicked() {
      this.$emit("deleteClicked");
    },
    activeToggle(value) {
      this.$emit("activeToggle", value);
    },
  },
  created(){
    this.toggleValue = this.active
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.v-small-dialog__content {
  width: 152px;
}
.more-options-container {
  width: 9.5rem;
  .more-options-item {
    padding: 0.875rem 1rem;
    cursor: pointer;
  }
  .divider {
    height: 1px;
    background: #e6e6e6;
  }
}
</style>