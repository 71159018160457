<template>
  <v-dialog
    v-model="dialog"
    width="504"
    persistent
    overlay-color="rgba(0, 3, 23, 0.8)"
    overlay-opacity="0.95"
  >
    <v-card class="layout-card">
      <v-card-title>
        <v-layout justify-space-between>
          <div class="confirmation-title">{{ title }}</div>
        </v-layout>
      </v-card-title>
      <v-card-text class="confirmation-content">
        <slot name="content">{{ content }}</slot>
      </v-card-text>
      <v-card-actions>
        <div>
          <slot name="action"></slot>
        </div>
        <v-spacer></v-spacer>
        <Button :label="cancel" type="ghost" width="84px" @click="onCancel" />
        <Button
          class="close-btn ml-4"
          :label="accept"
          type="primary"
          width="84px"
          @click="onConfirm(false)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    content: {
      type: String,
      default: "",
      required: false,
    },
    cancel: {
      type: String,
      default: "",
      required: false,
    },
    accept: {
      type: String,
      default: "",
      required: false,
    },
    buttonClass: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    dialog: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    onConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.container {
  background: rgba(0, 3, 23, 0.8);
  width: 100%;
}
.layout-card {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: max-content;
}
.confirmation-title {
  font-weight: 500;
  font-size: 1rem !important;
  margin: 0.875rem 0 -1rem 0.875rem;
}
.confirmation-content {
  font-weight: normal;
  font-size: 15px;
  margin: 0.5rem 0 0.625rem 0.875rem;
  line-height: 1.563rem;
  color: #0d1842;
}
.v-card__actions {
  background: rgba(0, 0, 0, 0.02);
  padding: 0.75rem 1.875rem 0.875rem 0;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90% !important;
  position: absolute !important;
  top: -2.2rem !important;
  overflow-x: hidden;
}
.v-card__text {
  line-height: 1.313rem !important;
  padding: 0 25px 20px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  font-size: 0.875rem;
}
</style>
