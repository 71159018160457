import Vue from "vue";
import { _get } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    orders: [],
    searchQuery: "",
    ordersPage: 1,
    totalOrders: 0,
    ordersFromDate: "",
    ordersToDate: "",
    type: "",
    logisticsDropdown: [],
    logisticId: "",
  },
  getters: {
    orders: ({ orders }) => orders,
    totalOrders: ({ totalOrders }) => totalOrders,
    ordersPage: ({ ordersPage }) => ordersPage,
    ordersFromDate: ({ ordersFromDate }) => ordersFromDate,
    ordersToDate: ({ ordersToDate }) => ordersToDate,
    logisticsDropdown: ({ logisticsDropdown }) => logisticsDropdown,
  },
  mutations: {
    setOrders(storeState, { orders, ordersCount }) {
      storeState.orders = orders;
      storeState.totalOrders = ordersCount;
    },
    setPage(storeState, payload) {
      storeState.ordersPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.ordersFromDate = dateRange ? dateRange.fromDate : "";
      storeState.ordersToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
        setLogisticsDropdown(storeState, company) {
      storeState.logisticsDropdown = company.map((obj) => {
        obj.name = obj.company_name;
        return obj;
      });
    },
    setLogisticId(storeState, payload) {
      storeState.logisticId = payload
    },
  },
  actions: {
    async setLogisticFilter({ commit, state }, payload) {
      const {
        ordersPage,
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
      } = state;
      const { data } = await _get(
        `${API.MERCHANT.ORDERS.FETCH}/getAll/${payload.id}?page=${ordersPage}&search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&dateType=${type}&logisticID=${payload.logistic_id}&limit=5`
      );
      commit("setLogisticId", payload.logistic_id);
      commit("setOrders", data);
    },
    setLogisticIDEmpty({ commit }) {
      commit("setLogisticId", "");
    },
    async getOrders({ commit, state }, payload) {
      const {
        ordersPage,
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
        logisticId,
      } = state;
      const { data } = await _get(
        `${API.MERCHANT.ORDERS.FETCH}/getAll/${payload.id}?page=${ordersPage}&search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&dateType=${type}&logisticID=${logisticId}&limit=5`
      );
      commit("setOrders", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async getLogisticsDropdown({ commit }, payload) {
      const { data } = await _get(`${API.MERCHANT.ORDERS.FETCH_LOGISTIC_DROPDOWN}/${payload}`);
      commit("setLogisticsDropdown", data);
    },
    exportOrder({ state }, payload) {
      const {
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
        logisticId,
      } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.MERCHANT.ORDERS.FETCH}/export/orders/${payload}?search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&logisticID=${logisticId}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
  },
};
