import { API } from "../config/constants";
import { _get } from "../services/crudService";

export default {
    namespaced: true,

    state: {
        count: {
            requestCount: 0,
            driverCount: 0,
            sortingCenterCount: 0,
        },
    },
    getters: {
        count: ({ count }) => count
    },
    mutations: {
        setLogisticsTabCount(storeState, { requestCount, driverCount, sortingCenterCount }) {
            storeState.count.requestCount = requestCount
            storeState.count.driverCount = driverCount
            storeState.count.sortingCenterCount = sortingCenterCount
        },
    },
    actions: {
        async getLogisticsTabCount({ rootState, commit }) {
            const { data } = await _get(
                `${API.COUNT.FETCH}?companyID=${rootState.logistics.authentication.loggedUser.company_id}`
            );
            commit('setLogisticsTabCount', data)
        }
    }
}