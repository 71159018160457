import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    users: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalUsers: 0,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    users: ({ users }) => users,
    totalUsers: ({ totalUsers }) => totalUsers,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setUsers(storeState, { users, usersCount }) {
      storeState.users = users;
      storeState.totalUsers = usersCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getUsers({ commit, state }) {
      const { page, searchQuery, fromDate, toDate, type, limit } = state;
      const { data } = await _get(
        `${API.USERS.FETCH}?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&limit=${limit}`
      );
      commit("setUsers", data);
    },
    async addUser(storeState, payload) {
      await _post(API.USERS.CREATE, payload);
    },
    async updateUser(storeState, payload) {
      await _put(`${API.USERS.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteUser(storeState, payload) {
      await _delete(`${API.USERS.DELETE}/${payload.id}`);
    },
    async setActiveUser(storeState, payload) {
      await _put(`${API.USERS.ACTIVE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportUsers({ state }) {
      const { page, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.USERS.FETCH}/export/users?page=${page}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
  },
};
