import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_MERCHANT_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    warehouses: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalWarehouse: 0,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    warehouses: ({ warehouses }) => warehouses,
    totalWarehouse: ({ totalWarehouse }) => totalWarehouse,
    page: ({ page }) => page,
    warehousePage: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setWarehouse(storeState, { warehouses, warehousesCount }) {
      warehouses.map((warehouse) => {
        warehouse.warehouseWithAddress = `${warehouse.warehouse_name}, ${warehouse.address}`
        warehouse.warehouse_nameWithAddress = `${warehouse.warehouse_name}, ${warehouse.address}, ${warehouse.cityortown}`;
      });
      storeState.warehouses = warehouses;
      storeState.totalWarehouse = warehousesCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getWarehouses({ commit, state, rootState }) {
      const { page, searchQuery, fromDate, toDate, type, limit } = state;
      const { data } = await _get(
        `${API.WAREHOUSE.FETCH}?page=${page}&limit=${limit}&search=${searchQuery}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setWarehouse", data);
    },
    async addWarehouse(storeState, payload) {
      await _post(API.WAREHOUSE.CREATE, payload);
    },
    async updateWarehouse(storeState, payload) {
      await _put(`${API.WAREHOUSE.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteWarehouse(storeState, payload) {
      await _delete(`${API.WAREHOUSE.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportWarehouse({ state, rootState }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/merchant${API.WAREHOUSE.FETCH}/export/warehouses?page=${page}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      );
    },
  },
};
