<template>
  <div class="date-picker-width date-picker-space" v-click-outside="onBlur">
    <!--  <HeaderDatePicker
        v-if="showDatePickers"
        label="From Date"
        class="ml-4 mr-4"
        icon="calendar-dark"
        :dateValue="formatDate(fromDate)"
        @change="assignFromDate"
      />
      <HeaderDatePicker
        v-if="showDatePickers"
        label="To Date"
        class="mr-4"
        icon="calendar-dark"
        :dateValue="formatDate(toDate)"
        @change="assignToDate"
        :minDate="fromDate"
        :disabled="!fromDate"
      /> -->
    <v-text-field
      :placeholder="label"
      :value="computedDate"
      @change="getChange"
      :disabled="disabled"
      @focus="iconClicked"
    >
      <template v-slot:append>
        <img
          width="18"
          height="18"
          :src="require(`@/assets/icons/${icon}.svg`)"
          @click="iconClicked"
          alt="calendar-icon"
        />
      </template>
    </v-text-field>
    <div v-if="showDatePicker" class="datePicker">
      <v-date-picker
        color="#FF003F"
        v-model="date"
        @change="formatDate"
        :min="minDate"
        :max="maxDate"
        :key="datePickerKey"
        :show-current="start"
      ></v-date-picker>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "HeaderDatePicker",
  props: [
    "dateValue",
    "minDate",
    "maxDate",
    "disabled",
    "startDate",
    "label",
    "icon",
  ],
  data() {
    return {
      picker: true,
      date: "",
      showDatePicker: false,
      datePickerKey: 0,
      start: "",
    };
  },
  computed: {
    computedDate() {
      return this.dateValue
        ? moment(this.dateValue).format("DD MMM, YYYY")
        : "";
    },
  },
  methods: {
    formatDate() {
      this.picker = false;
      this.showDatePicker = false;
      this.$emit("change", this.date);
    },
    iconClicked() {
      if (this.dateValue) {
        this.date = moment(this.dateValue).format("YYYY-MM-DD");
        this.start = new Date(this.date).toISOString().substr(0, 10);
        this.datePickerKey += 1;
      } else {
        this.start = this.startDate;
        this.datePickerKey += 1;
      }
      this.showDatePicker = !this.showDatePicker;
    },
    onBlur() {
      this.showDatePicker = false;
    },
    getChange(value) {
      if (!value.toString().length) {
        this.$emit("empty");
      }
    },
  },
  created() {
    if (this.dateValue) {
      this.date = moment(this.dateValue).format("YYYY-MM-DD");
      this.start = new Date(this.date).toISOString().substr(0, 10);
      this.datePickerKey += 1;
    } else {
      this.start = this.startDate;
      this.datePickerKey += 1;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.datePicker {
  margin-top: -19px;
  position: absolute;
  z-index: 8;
}
::v-deep .v-picker__title__btn {
  opacity: unset !important;
}
::v-deep .v-picker {
  border: 1px solid #dce2f0 !important;
}
::v-deep .v-btn__content {
  font-weight: 500;
  font-size: 12px;
}
::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border: none !important;
}
.date-picker-space {
  ::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #dce2f0 !important;
  }
  ::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:after {
    border: none !important;
  }
  ::v-deep
    .textAreaError.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: red !important;
  }

  ::v-deep .v-text-field input {
    margin-left: 6px !important;
  }

  ::v-deep .v-input input {
    max-height: unset !important;
  }
  ::v-deep .v-input {
    height: 40px;
    background: $white-color;
    padding: 2px 10px 2px 10px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
  ::v-deep .v-text-field .v-input__append-inner,
  .v-text-field .v-input__prepend-inner {
    margin-top: 7px !important;
  }
}
</style>
