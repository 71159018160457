<template>
  <div>
    <v-autocomplete
      v-model="editValue"
      :items="typeDataSet"
      dense
      filled
      :label="label"
      item-text="name"
      return-object
      @change="onSelectChange"
      :class="{
        textAreaError: error,
        required: required,
        'custom-icon': isDatePicker,
      }"
      :disabled="disabled"
      :multiple="multiple"
      :chips="multiple"
      :small-chips="multiple"
      @focus="focus"
      :key="selectKey"
    >
      <template v-if="isDatePicker" v-slot:append-item>
        <v-menu
          ref="menu"
          v-model="isDateOpen"
          :return-value.sync="date"
          transition="scale-transition"
          min-width="auto"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="custom-range">
              Custom Range
            </div>
          </template>
          <v-date-picker v-model="date" no-title range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isDateOpen = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="onSelectChange('Custom Range')">
              Apply
            </v-btn>
          </v-date-picker>
        </v-menu>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import moment from "moment";
import lodash from "lodash";

export default {
  name: "SelectField",
  props: [
    "dataSet",
    "label",
    "value",
    "disabled",
    "required",
    "error",
    "multiple",
    "isDatePicker",
  ],
  data() {
    return {
      editValue: "",
      isDateOpen: false,
      date: [],
      typeDataSet: [],
      fromDate: "",
      toDate: "",
      selectKey: 10,
    };
  },
  methods: {
    focus() {
      this.typeDataSet = lodash.cloneDeep(this.dataSet);
      if (this.typeDataSet.indexOf(this.editValue) < 0) this.editValue = "";
      this.$emit("focus");
    },
    async onSelectChange(value) {
      switch (value) {
        case "Today":
          this.fromDate = moment()
            .startOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("day")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Week":
          this.fromDate = moment()
            .startOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("week")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "This Month":
          this.fromDate = moment()
            .startOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("month")
            .utc()
            .local()
            .format("YYYY-MM-DD");
          break;
        case "Custom Range":
          this.customRange();
          break;
        default:
          if (!moment(value.split(" - ")[0]).isAfter(value.split(" - ")[1])) {
            this.fromDate = moment(value.split(" - ")[0]).format("YYYY-MM-DD");
            this.toDate = moment(value.split(" - ")[1]).format("YYYY-MM-DD");
          } else {
            this.fromDate = moment(value.split(" - ")[1]).format("YYYY-MM-DD");
            this.toDate = moment(value.split(" - ")[0]).format("YYYY-MM-DD");
          }
      }
      this.$emit("change", {
        fromDate: this.fromDate,
        toDate: this.toDate,
        value,
      });
      this.dataKey += 1;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    customRange() {
      if (this.date.length === 1) {
        this.selectKey += 1;
        this.isDateOpen = false;
        return;
      } else {
        let selectedDates = [];
        if (!moment(this.date[0]).isAfter(this.date[1])) {
          selectedDates = [...this.date];
          this.fromDate = selectedDates[0];
          this.toDate = selectedDates[1];
        } else {
          selectedDates = [...this.date];
          this.fromDate = selectedDates[1];
          this.toDate = selectedDates[0];
        }
        this.isDateOpen = false;
        this.selectKey += 1;
        this.typeDataSet.push(
          `${this.formatDate(this.fromDate)} - ${this.formatDate(this.toDate)}`
        );
        this.editValue = `${this.formatDate(this.fromDate)} - ${this.formatDate(
          this.toDate
        )}`;
        this.date = [];
      }
    },
  },
  mounted() {
    this.editValue = this.value;
    this.typeDataSet = lodash.cloneDeep(this.dataSet);
  },
  watch: {
    value() {
      this.editValue;
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.custom-range {
  font-size: 0.8125rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 0.7rem 1rem;
}
.custom-icon {
  ::v-deep .mdi-menu-down::before {
    content: url("../../assets/icons/calendar-darker.svg");
  }
  ::v-deep .v-icon {
    transform: rotate(360deg) !important;
  }
}
::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  border-radius: 1px;
  padding-bottom: 10px;
  min-height: 64px !important;
}
::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
  min-height: 64px !important;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
