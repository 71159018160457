<template>
  <div class="not-found-container exact-center flex-col position-relative">
    <h1 class="mb-4">404</h1>
    <img
      class="image-layout mb-6"
      src="@/assets/icons/login-img.svg"
      alt="not-found"
    />
    <div class="content">
      The page you were looking for does not exist
    </div>
    <div class="display-flex justify-center mt-4 mb-12">
      <div v-for="i in 3" :key="i">
        <img class="ml-2" src="@/assets/icons/diamond-icon.svg" />
      </div>
    </div>
    <Button
      @click="onBackClick"
      label="Go Back"
      type="secondary"
      width="137px"
    /> 
    <div class="login-rights position-absolute">
      2021 Shiok All rights reserved
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
  data() {
    return {};
  },
  methods: {
    onBackClick() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "@/styles/auth.scss";

.not-found-container {
  min-height: 100vh;
  h1 {
    font-weight: 500;
    font-size: 6.25rem;
    color: #000000;
  }
  .content {
    width: 22.5rem;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
  }
}
</style>
