<template>
  <div class="no-content exact-center flex-col position-relative">
    <img class="image-layout mb-6" src="@/assets/icons/no-access.svg" alt="no-content" />
    <h1>Sorry, you don't have access to view this section</h1>
  </div>
</template>

<script>
export default {
  name: "NoContent",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.no-content {
  h1 {
    font-size: 18px;
    color: $secondary-color;
  }
}
</style>
