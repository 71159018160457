<template>
  <v-app>
    <v-main class="main-container">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
<style lang="scss">
@import "@/styles/common.scss";
.main-container {
  min-height: calc(100vh - 6rem);
  background: #f6f6f7;
}
</style>
