import Vue from "vue";
import { _get } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    requests: [],
    searchQuery: "",
    requestPage: 1,
    totalRequest: 0,
    requestFromDate: "",
    requestToDate: "",
    type: "",
    merchantId: "",
    merchantDropdown: [],
  },
  getters: {
    requests: ({ requests }) => requests,
    totalRequest: ({ totalRequest }) => totalRequest,
    requestPage: ({ requestPage }) => requestPage,
    requestFromDate: ({ requestFromDate }) => requestFromDate,
    requestToDate: ({ requestToDate }) => requestToDate,
    merchantDropdown: ({ merchantDropdown }) => merchantDropdown,
  },
  mutations: {
    setRequests(storeState, { requests, requestsCount }) {
      storeState.requests = requests;
      storeState.totalRequest = requestsCount;
    },
    setPage(storeState, payload) {
      storeState.requestPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setMerchantId(storeState, payload) {
      storeState.merchantId = payload
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.requestFromDate = dateRange ? dateRange.fromDate : "";
      storeState.requestToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setMerchantDropdown(storeState, company) {
      storeState.merchantDropdown = company.map((obj) => {
        obj.name = obj.company_name;
        return obj;
      });
    },
  },
  actions: {
    async setMerchantFilter({ commit, state }, payload) {
      const {
        requestPage,
        searchQuery,
        requestFromDate,
        requestToDate,
        type,
      } = state;
      const { data } = await _get(
        `${API.LOGISTIC.REQUESTS.FETCH}/getAll/${payload.id}?page=${requestPage}&limit=5&search=${searchQuery}&fromDate=${requestFromDate}&toDate=${requestToDate}&dateType=${type}&merchantID=${payload.merchant_id}`
      );
      commit("setMerchantId", payload.merchant_id);
      commit("setRequests", data);
    },
    setLogisticIDEmpty({ commit }) {
      commit("setMerchantId", "");
    },
    async getRequests({ commit, state }, payload) {
      const {
        requestPage,
        searchQuery,
        requestFromDate,
        requestToDate,
        type,
        merchantId
      } = state;
      const { data } = await _get(
        `${API.LOGISTIC.REQUESTS.FETCH}/getAll/${payload.id}?page=${requestPage}&limit=5&search=${searchQuery}&fromDate=${requestFromDate}&toDate=${requestToDate}&dateType=${type}&merchantID=${merchantId}`
      );
      commit("setRequests", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async getMerchantDropdown({ commit }, payload) {
      const { data } = await _get(`${API.LOGISTIC.REQUESTS.FETCH_MERCHANT_DROPDOWN}/${payload}`);
      commit("setMerchantDropdown", data);
    },
    exportRequest({ state }, payload) {
      const {
        searchQuery,
        requestFromDate,
        requestToDate,
        type,
        merchantId,
      } = state;
      submitPostForm(
        `${SERVER_URL}/admin/${API.LOGISTIC.REQUESTS.FETCH}/export/orders/${payload}?search=${searchQuery}&fromDate=${requestFromDate}&toDate=${requestToDate}&dateType=${type}&merchantID=${merchantId}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
  },
};
