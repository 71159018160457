import { _delete, _get, _post, _put } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    warehouses: [],
    searchQuery: "",
    merchantWarehousePage: 1,
    totalWarehouse: 0,
    warehouseFromDate: "",
    warehouseToDate: "",
    type: "",
  },
  getters: {
    warehouses: ({ warehouses }) => warehouses,
    totalWarehouse: ({ totalWarehouse }) => totalWarehouse,
    merchantWarehousePage: ({ merchantWarehousePage }) => merchantWarehousePage,
    warehouseFromDate: ({ warehouseFromDate }) => warehouseFromDate,
    warehouseToDate: ({ warehouseToDate }) => warehouseToDate,
  },
  mutations: {
    setWarehouse(storeState, { warehouses, warehousesCount }) {
      storeState.warehouses = warehouses;
      storeState.totalWarehouse = warehousesCount;
    },
    setPage(storeState, payload) {
      storeState.merchantWarehousePage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.warehouseFromDate = dateRange ? dateRange.fromDate : "";
      storeState.warehouseToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getWarehouses({ commit, state }, payload) {
      const {
        merchantWarehousePage,
        searchQuery,
        warehouseFromDate,
        warehouseToDate,
        type,
      } = state;
      const { data } = await _get(
        `${API.MERCHANT.WAREHOUSE.FETCH}/getAll/${payload.id}?page=${merchantWarehousePage}&search=${searchQuery}&fromDate=${warehouseFromDate}&toDate=${warehouseToDate}&dateType=${type}&limit=5`
      );
      commit("setWarehouse", data);
    },
    async addWarehouse(storeState, payload) {
      await _post(API.MERCHANT.WAREHOUSE.CREATE, payload);
    },
    async updateWarehouse(storeState, payload) {
      await _put(
        `${API.MERCHANT.WAREHOUSE.UPDATE}/${payload.id}`,
        payload.data
      );
    },
    async deleteWarehouse(storeState, payload) {
      await _delete(`${API.MERCHANT.WAREHOUSE.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
  },
};
