<template>
  <v-skeleton-loader
    :width="width"
    :height="height"
    light
    :type="type"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    width: {
      default: "100",
    },
    height: {
      default: "100",
    },
    type: {
      default: "card",
    },
  },
};
</script>

<style lang="scss"></style>
