export const API = {
  AUTHENTICATION: {
    FORGOT_PASSWORD: "auth/forgot-password",
    RESET_PASSWORD: "auth/reset-password",
    SIGNIN: "auth/signin",
    CHANGE_PASSWORD: "users/profile/change-password",
  },
  DASHBOARD: {
    FETCH_COUNT: "dashboard/count",
    FETCH_COUNT_CUSTOMER: "dashboard/customers/count",
    FETCH_ORDER_TRACKING: "dashboard/order-tracking",
    FETCH_ORDER_GRAPH: "dashboard/week-orders",
    FETCH_ORDER_SUMMARY: "dashboard/order-summary",
    ORDER_TRACK: "dashboard/tracking-details",
  },
  WAREHOUSE: {
    FETCH: "/warehouse",
    CREATE: "/warehouse",
    DELETE: "/warehouse",
    UPDATE: "/warehouse",
  },
  SETTINGS: {
    FETCH: "/roles/all",
    FETCH_BY_ID: "/roles",
    CREATE: "/roles",
    DELETE: "/roles",
    UPDATE: "/roles",
    UPDATE_PERMISSION: "/permissions",
    UPDATE_MERCHANT: "/users/company/edit-details",
  },
  ORDERS: {
    FETCH: "/order",
    CREATE: "/order",
    DELETE: "/order",
    UPDATE: "/order",
    UPDATE_BILLING: "/order/billingAddress",
    FETCH_LOGISTIC: "/logistics",
    FETCH_FAV_LOGISTIC: "/order/favourite/getall",
    SET_FAV_LOGISTIC: "/order/favourite/logistics",
    ASSIGN_LOGISTIC: "/order/assign/logistics",
    REASSIGN_LOGISTIC: "/order/reassign/logistics",
    UOM_FETCH: "order/uom-details",
  },
  USERS: {
    FETCH: "/users",
    CREATE: "/users",
    DELETE: "/users",
    UPDATE: "/users",
    ACTIVE: "/users/make-active",
    FEEDBACK: "/users/send-feedback",
  },
  COMPANY_LOGO: {
    UPDATE: "/users/merchant-photo",
  },
  PRODUCT: {
    FETCH: "/product",
    CREATE: "/product",
    DELETE: "/product",
    UPDATE: "/product",
    CATEGORY: "/product/category/getAll",
    PRODUCT_BY_CATEGORY: "/product/category/getProductByCategory",
    BRAND: "/product/brand/getAll",
  },
  CUSTOMERS: {
    FETCH: "/customers/get-merchant-customer",
    UPDATE: "customers",
    ORDERS: {
      FETCH: "customers/get-merchant-customer-orders",
    },
    ORDERS_FETCH: "customers/drop-down/getAll"
  },
  LOGS: {
    FETCH: "/order-logs",
  },
  COUNT: {
    FETCH: "/count",
  },
};

export const SHIOK_MERCHANT_TOKEN = "shiokMerchantToken";

export const ROUTES = {
  AUTH: "/auth",
  SIGNIN: "/auth/login/merchant",
  DASHBOARD: "/merchant/dashboard",
};
