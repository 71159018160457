module.exports = {
    submitPostForm(url, label, value) {
      const f = document.createElement('form')
      f.setAttribute('method', 'post') // Set method
      f.setAttribute('action', url) // Set API end point
      f.setAttribute('target', '_blank')
  
      const i = document.createElement('input')
      i.setAttribute('type', 'text')
      i.setAttribute('name', label) // Set token label
      i.setAttribute('value', value) // Set token value
  
      const s = document.createElement('input')
      s.setAttribute('type', 'submit')
      s.setAttribute('value', 'Submit')
  
      // Append link and download
      f.appendChild(i)
      f.appendChild(s)
      document.body.appendChild(f)
      s.click()
      f.remove()
    },
  }
  