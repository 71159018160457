<template>
  <button
    ref="button"
    class="btn exact-center"
    :style="{
      minWidth: width,
    }"
    :class="buttonClasses()"
    :disabled="disabled"
    @click="onButtonClick"
  >
    <Spinner v-if="loading" size="20" />
    <span v-else>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: ["label", "width", "type", "disabled", "loading"],
  methods: {
    onButtonClick() {
      this.$emit("click");
    },
    buttonClasses() {
      const classes = [];
      if (this.type === "primary") {
        classes.push("primary-btn");
      } else if (this.type === "secondary") {
        classes.push("secondary-btn");
      } else if (this.type === "cancel") {
        classes.push("cancel-btn");
      } else if (this.type === "save") {
        classes.push("save-btn");
      }
      if (this.disabled || this.loading) {
        classes.push("btn-disabled");
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common";

.btn {
  min-height: 2.188rem;
  font-weight: 500;
  font-size: 0.75rem;
  &:hover {
    box-shadow: unset !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
}
.primary-btn {
  background: $primary-color;
  color: #ffffff !important;
  border-radius: 0.25rem;
}
.secondary-btn {
  background: $secondary-color;
  color: #ffffff;
  border-radius: 0.25rem;
}
.cancel-btn {
  background: $white-color;
  border: 0.063rem solid #dce2f0;
  color: #999da0;
  border-radius: 0.5rem;
}
.save-btn {
  background: $primary-color;
  color: #ffffff !important;
  border-radius: 0.5rem;
}
.btn-disabled {
  background: rgba(13, 24, 66, 0.05) !important;
  color: rgba(13, 24, 66, 0.2) !important;
  cursor: not-allowed;
}
</style>
