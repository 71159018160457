import Vue from "vue";
// import store from '../../store/store'
import {
  ROUTES,
  SHIOK_ADMIN_TOKEN,
  SHIOK_MERCHANT_TOKEN,
  SHIOK_LOGISTIC_TOKEN,
} from "../config/constants";

/**
 * Authentication Redirect
 */
const authRedirect = async (type) => {
  const currentToken =
    type === "admin"
      ? Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      : type === "merchant"
      ? Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      : Vue.$cookies.get(SHIOK_LOGISTIC_TOKEN);
  if (currentToken) {
    return type === "admin"
      ? ROUTES.ADMIN_DASHBOARD
      : type === "merchant"
      ? ROUTES.MERCHANT_DASHBOARD
      : ROUTES.LOGISTIC_DASHBOARD;
  }
  return type === "admin"
    ? ROUTES.ADMIN_SIGNIN
    : type === "merchant"
    ? ROUTES.MERCHANT_SIGNIN
    : ROUTES.LOGISTIC_SIGNIN;
};

/**
 * params {Object} to
 * params {Object} from
 * params {callback} next
 */
const AuthGuard = async (to, from, next) => {
  const type = to?.params?.type;
  const currentToken =
    type === "admin"
      ? Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      : type === "merchant"
      ? Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      : Vue.$cookies.get(SHIOK_LOGISTIC_TOKEN);
  if (currentToken) {
    if (to.path === "/" || to.path.startsWith(ROUTES.AUTH)) {
      return next({ path: await authRedirect(type) });
    } else {
      return next();
    }
  }

  if (!to.path.startsWith(ROUTES.AUTH)) {
    return next({
      path: ROUTES.ADMIN_SIGNIN,
      query: {
        url: to.path,
      },
    });
  }
  next();
};

export { AuthGuard };
