import { API } from "../config/constants";
import { _get } from "../services/crudService";

export default {
    namespaced: true,

    state: {
        count: {
            customerCount: 0,
            orderCount: 0,
            productCount: 0,
            warehouseCount: 0
        },
    },
    getters: {
        count: ({ count }) => count
    },
    mutations: {
        setMerchantTabCount(storeState, { customerCount, orderCount, productCount, warehouseCount }) {
            storeState.count.customerCount = customerCount
            storeState.count.orderCount = orderCount
            storeState.count.productCount = productCount
            storeState.count.warehouseCount = warehouseCount
        },
    },
    actions: {
        async getMerchantTabCount({ rootState, commit }) {
            const { data } = await _get(
                `${API.COUNT.FETCH}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
            );
            commit('setMerchantTabCount', data)
        }
    }
}