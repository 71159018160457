import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_MERCHANT_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    products: [],
    productsByCategory: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalProducts: 0,
    categories: [],
    brands: [],
    fromDate: "",
    toDate: "",
    type: "",
    isProductFilter: false,
    productBrandFilter: "",
    productNameFilter: "",
    sortType: "",
    productCategoryFilter: "",
  },
  getters: {
    products: ({ products }) => products,
    productsByCategory: ({ productsByCategory }) => productsByCategory,
    totalProducts: ({ totalProducts }) => totalProducts,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    categories: ({ categories }) => categories,
    brands: ({ brands }) => brands,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    isProductFilter: ({ isProductFilter }) => isProductFilter,
    productBrandFilter: ({ productBrandFilter }) => productBrandFilter,
    productNameFilter: ({ productNameFilter }) => productNameFilter,
    sortType: ({ sortType }) => sortType,
    productCategoryFilter: ({ productCategoryFilter }) => productCategoryFilter,
  },
  mutations: {
    setProducts(storeState, { products, productsCount }) {
      storeState.products = products.map((obj) => {
        obj.actions = true;
        return obj;
      });
      storeState.totalProducts = productsCount;
    },
    setProductsByCategoryInProduct(storeState, { product }) {
      storeState.productsByCategory = product;
    },
    setProductsByCategory(storeState, { product }) {
      storeState.products = product;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setProductCategories(storeState, payload) {
      storeState.categories = payload.map((obj) => {
        return obj.product_category;
      });
    },
    setProductBrands(storeState, payload) {
      storeState.brands = payload.map((obj) => {
        return obj.product_brand;
      });
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setFilterValues(storeState, payload) {
      if (payload !== null) {
        const {
          productNameFilter,
          sortType,
          productCategoryFilter,
          productBrandFilter,
          dateRange,
          type,
        } = payload;
        storeState.productBrandFilter = productBrandFilter
          ? productBrandFilter
          : "";
        storeState.productNameFilter = productNameFilter
          ? productNameFilter
          : "";
        storeState.sortType = sortType ? sortType : "";
        storeState.productCategoryFilter = productCategoryFilter
          ? productCategoryFilter
          : "";
        storeState.fromDate = dateRange ? dateRange.fromDate : "";
        storeState.toDate = dateRange ? dateRange.toDate : "";
        storeState.type = type ? type : "";
        storeState.isProductFilter = true;
      } else {
        storeState.productBrandFilter = "";
        storeState.productNameFilter = "";
        storeState.sortType = "";
        storeState.productCategoryFilter = "";
        storeState.fromDate = "";
        storeState.toDate = "";
        storeState.type = "";
        storeState.isProductFilter = false;
      }
    },
  },
  actions: {
    async getProducts({ commit, state, rootState }) {
      const {
        page,
        searchQuery,
        fromDate,
        toDate,
        type,
        limit,
        productBrandFilter,
        productNameFilter,
        sortType,
        productCategoryFilter,
      } = state;
      const { data } = await _get(
        `${API.PRODUCT.FETCH}?page=${page}&limit=${limit}&search=${searchQuery}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&sortType=${sortType}&productCategoryFilter=${productCategoryFilter}&productNameFilter=${productNameFilter}&productBrandFilter=${productBrandFilter}`
      );
      commit("setProducts", data);
    },
    async addProduct(storeState, payload) {
      await _post(API.PRODUCT.CREATE, payload);
    },
    async updateProduct(storeState, payload) {
      await _put(`${API.PRODUCT.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteProduct(storeState, payload) {
      await _delete(`${API.PRODUCT.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    setFilterValues({ commit }, payload) {
      commit("setFilterValues", payload);
    },
    async getProductCategories({ commit, rootState }) {
      const { data } = await _get(
        `${API.PRODUCT.CATEGORY}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
      );
      commit("setProductCategories", data.product);
    },
    async getProductsByCategory({ commit, rootState }, payload) {
      const { data } = await _post(
        `${API.PRODUCT.PRODUCT_BY_CATEGORY}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`,
        payload
      );
      commit("setProductsByCategory", data);
    },
    async getProductsByCategoryInProduct({ commit, rootState }, payload) {
      const { data } = await _post(
        `${API.PRODUCT.PRODUCT_BY_CATEGORY}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`,
        payload
      );
      commit("setProductsByCategoryInProduct", data);
    },
    async getProductBrands({ commit, rootState }) {
      const { data } = await _get(
        `${API.PRODUCT.BRAND}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
      );
      commit("setProductBrands", data.product);
    },

    async exportProducts({ state, rootState }) {
      const {
        page,
        limit,
        searchQuery,
        fromDate,
        toDate,
        type,
        productBrandFilter,
        productNameFilter,
        sortType,
        productCategoryFilter,
      } = state;
      submitPostForm(
        `${SERVER_URL}/merchant${API.PRODUCT.FETCH}/export/products?page=${page}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&sortType=${sortType}&productCategoryFilter=${productCategoryFilter}&productNameFilter=${productNameFilter}&productBrandFilter=${productBrandFilter}`,
        "token",
        Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      );
    },
  },
};
