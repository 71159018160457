import Vue from "vue";
import { API, SHIOK_MERCHANT_TOKEN } from "../config/constants";
import { SHIOK_LOGISTICS_TOKEN } from '../../Logistics/config/constants'
import { SHIOK_ADMIN_TOKEN } from '../../Admin/config/constants'
import { _get, _post, _put } from "../services/crudService";

export default {
  namespaced: true,

  state: {
    loggedUser: {},
    loggedUserPermission: {},
  },
  getters: {
    loggedUser: ({ loggedUser }) => loggedUser,
    loggedUserPermission: ({ loggedUserPermission }) => loggedUserPermission,
  },
  mutations: {
    setLoggedUserData(storeState, payload) {
      storeState.loggedUser = payload;
    },
    setLoggedUserPermission(storeState, payload) {
      let permissions = {};
      payload.map((item) => {
        const { resource, allow } = item;
        permissions = { ...permissions, [resource.abbreviation]: allow };
      });
      storeState.loggedUserPermission = permissions;
    },
  },
  actions: {
    async login({ dispatch }, payload) {
      const { data } = await _post(API.AUTHENTICATION.SIGNIN, payload);
      const { token: tokenData } = data;
      const { token } = tokenData;
      Vue.$cookies.set(SHIOK_MERCHANT_TOKEN, token);
      await dispatch('getLoggedUserData')
    },
    async logout({ commit }) {
      commit("setLoggedUserData", {});
      Vue.$cookies.remove(SHIOK_ADMIN_TOKEN);
      Vue.$cookies.remove(SHIOK_MERCHANT_TOKEN);
      Vue.$cookies.remove(SHIOK_LOGISTICS_TOKEN);
    },
    async forgetPassword(storeState, payload) {
      await _post(API.AUTHENTICATION.FORGOT_PASSWORD, payload);
    },
    async resetPassword(storeState, payload) {
      await _post(API.AUTHENTICATION.RESET_PASSWORD, payload);
    },
    async getLoggedUserData({ commit }) {
      const { data } = await _get(`${API.USERS.FETCH}/my-profile`);
      const { user, permission } = data;
      commit("setLoggedUserData", user);
      commit("setLoggedUserPermission", permission);
    },
    async updateCurrentUser({ dispatch }, payload) {
      await _put(API.USERS.UPDATE, payload.user);
      await _put(`${API.COMPANY_LOGO.UPDATE}/${payload.company.id}`, payload?.company?.formData);
      dispatch("getLoggedUserData");
    },
    async changePassword(storeState, payload) {
      await _put(API.AUTHENTICATION.CHANGE_PASSWORD, payload);
    },
  },
};
