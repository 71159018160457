import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import { BootstrapVue } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import variablepie from "highcharts/modules/variable-pie";
// import annotations from 'highcharts/modules/annotations';
import VuePhoneNumberInput from "vue-phone-number-input";
import VueCookies from "vue-cookies";
import * as VueGoogleMaps from "vue2-google-maps";
import Geocoder from "@pderas/vue2-geocoder";
import VueViewer from "v-viewer";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'viewerjs/dist/viewer.css';
import "./plugins/main";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import router from "./router";
import store from "./store";
import { GOOGLE_API_KEY } from "../constants";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.use(VueApexCharts);
Vue.use(VueCookies);
Vue.use(VueViewer);
Vue.component("apexchart", VueApexCharts);
Vue.use(HighchartsVue, {
  // highcharts: annotations(Highcharts),
  highcharts: variablepie(Highcharts),
});
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_API_KEY,
    libraries: "places",
  },
});
Vue.use(Geocoder, {
  defaultCountryCode: null,
  defaultLanguage: null,
  defaultMode: "address",
  googleMapsApiKey: GOOGLE_API_KEY,
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
