<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :label="label"
          :placeholder="placeholder"
          :value="date"
          readonly
          v-bind="attrs"
          v-on="on"
          filled
          @input="doActionAtInput(date)"
          @blur="doActionAtBlur(date)"
          :disabled="disabled"
          :class="{ required: required }"
        >
          <template v-slot:append>
            <img
              width="18"
              height="18"
              class="cursor-pointer"
              :src="require(`@/assets/icons/${icon}.svg`)"
              @click="menu=true"
              alt="calendar-icon"
            />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        :max="maxDate"
        :min="minDate"
        :show-current="start"
        @change="save"
        color="#ff003f"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    label: {
      type: String,
      required: true,
      default: "",
      description: "Defines the label of the textfield to be displayed",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
      description: "Defines the placeholder of the textfield",
    },
    icon: {
      type: String,
      required: true,
      default: "calendar",
      description: "Defines the icon of the textfield",
    },
    dateValue: {
      type: String,
      required: true,
      default: "",
      description: "Defines the value of the textfield",
    },
    minDate: {
      type: String,
      required: false,
      default: "1950-01-01",
      description: "Defines the value of min date",
    },
    maxDate: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
      description: "Defines the value of max date",
    },
    startDate: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
      description: "Defines the value of start date",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    date: null,
    menu: false,
    start: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    // menu(val) {
    //   if (val) {
    //     this.$refs.picker.activePicker = "YEAR";
    //   }
    // },
    date() {
      this.$emit("change", this.date);
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    doActionAtBlur(editValue) {
      this.$emit("onBlur", editValue);
    },
    doActionAtInput(editValue) {
      this.$emit("OnInput", editValue);
    },
  },
  mounted() {
    if (this.dateValue) {
      this.date = this.dateValue;
      this.start = this.dateValue;
    } else {
      this.start = this.startDate;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common";

::v-deep .v-picker__title__btn {
  opacity: unset !important;
}
::v-deep .v-picker {
  border: 1px solid #dce2f0 !important;
}
::v-deep .v-btn__content {
  font-weight: 500;
  font-size: 12px;
}
::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  box-sizing: border-box;
  border-radius: 1px;
  height: 64px;
}

::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-text-field input {
  margin-left: 6px !important;
}

::v-deep .v-input input {
  max-height: unset !important;
}
</style>
