import Vue from "vue";
import Vuex from "vuex";

import admin from "../components/Admin/store/index";
import merchant from "../components/Merchant/store/index";
import logistics from "../components/Logistics/store/index";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false, // No mutations outside of the store handlers
  modules: {
    admin,
    merchant,
    logistics,
  },
});
