<template>
  <v-snackbar v-model="dialog" top :timeout="timeout" v-if="snackbar">
    <div class="exact-center message-container">
      <div>{{ notification }}</div>
      <img
        src="@/assets/icons/white-close.svg"
        width="14"
        height="14"
        @click="closeAlert"
        class="ml-3 cursor-pointer"
      />
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: "Notification",
  props: ["message", "snackbar"],
  data() {
    return {
      dialog: "",
      notification: "",
      timeout: 2000,
    };
  },
  methods: {
    closeAlert() {
      this.$emit("closeAlert");
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.$emit("closeAlert");
    },
  },
  created() {
    this.dialog = this.snackbar;
    this.notification = this.message;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.message-container {
  width: 100%;
}
::v-deep .v-snack__wrapper {
  background: #4a5571 !important;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 3, 23, 0.08), 0px 3px 6px rgba(0, 3, 23, 0.12);
  max-width: 26.25rem !important;
}
.v-snack {
  color: #000210 !important;
}
.v-snack__content {
  padding-right: unset !important;
  font-size: 0.938rem;
  color: #ffffff !important;
}
::v-deep .v-snack__content {
  display: flex;
  color: #ffffff !important;
}
.v-snack__wrapper {
  box-shadow: 0 0.063rem 0.375rem rgba(0, 0, 0, 0.12) !important;
}
.message {
  color: #ffffff;
}
</style>
