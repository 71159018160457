import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_LOGISTICS_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    drivers: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalDrivers: 0,
    fromDate: "",
    toDate: "",
    type: "",
    statusFilter: "",
    deliveriesCount: 0,
    deliveryPage: 1,
    deliverySearchQuery: "",
    deliveryLimit: 5,
    deliveryFromDate: "",
    deliveryToDate: "",
    deliveryType: "",
    isDeliveryFilter: false,
    deliveries: [],
  },
  getters: {
    drivers: ({ drivers }) => drivers,
    totalDrivers: ({ totalDrivers }) => totalDrivers,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    deliveries: ({ deliveries }) => deliveries,
    deliveriesCount: ({ deliveriesCount }) => deliveriesCount,
    deliveriesPage: ({ deliveryPage }) => deliveryPage,
    deliverySearchQuery: ({ deliverySearchQuery }) => deliverySearchQuery,
    deliveryFromDate: ({ deliveryFromDate }) => deliveryFromDate,
    deliveryToDate: ({ deliveryToDate }) => deliveryToDate,
    deliveryType: ({ deliveryType }) => deliveryType,
    isDeliveryFilter: ({ isDeliveryFilter }) => isDeliveryFilter,
    statusFilter: ({ statusFilter }) => statusFilter,
    type: ({ type }) => type,
  },
  mutations: {
    setDrivers(storeState, { drivers, driversCount }) {
      storeState.drivers = drivers.map((obj) => {
        obj.actions = true;
        obj.selected = false;
        obj.deliverySelected = false;
        return obj;
      });
      storeState.totalDrivers = driversCount;
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setDeliveries(storeState, payload) {
      storeState.deliveries = payload.requests;
      storeState.deliveriesCount = payload.requestsCount;
    },
    setDeliveriesPage(storeState, payload) {
      storeState.deliveryPage = payload;
    },
    setDeliveriesSearchQuery(storeState, payload) {
      storeState.deliverySearchQuery = payload;
    },
    setDeliveriesDateRange(storeState, { type, dateRange }) {
      storeState.deliveryFromDate = dateRange ? dateRange.fromDate : "";
      storeState.deliveryToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setDeliveriesFilterValues(storeState, payload) {
      if (payload !== null) {
        const { statusFilter, dateRange, type } = payload;
        storeState.statusFilter = statusFilter ? statusFilter : "";
        storeState.deliveryFromDate = dateRange ? dateRange.fromDate : "";
        storeState.deliveryToDate = dateRange ? dateRange.toDate : "";
        storeState.deliveryType = type ? type : "";
        storeState.isDeliveryFilter = true;
      } else {
        storeState.statusFilter = "";
        storeState.deliveryFromDate = "";
        storeState.deliveryToDate = "";
        storeState.deliveryType = "";
        storeState.isDeliveryFilter = false;
      }
    },
  },
  actions: {
    async getDrivers({ commit, state, rootState }) {
      const { page, searchQuery, fromDate, toDate, limit, type } = state;
      const { data } = await _get(
        `${API.DRIVER.FETCH}?page=${page}&search=${searchQuery}&limit=${limit}&companyID=${rootState.logistics.authentication.loggedUser.company_id}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setDrivers", data);
    },
    async addDriver(storeState, payload) {
      await _post(API.DRIVER.CREATE, payload);
    },
    async updateDriver(storeState, payload) {
      await _put(`${API.DRIVER.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteDriver(storeState, payload) {
      await _delete(`${API.DRIVER.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportCustomers({ state, rootState }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/logistics${API.DRIVER.FETCH}/export/drivers?page=${page}&companyID=${rootState.logistics.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_LOGISTICS_TOKEN)
      );
    },
    async fetchDeliveries({ commit, state }, payload) {
      const {
        deliveryPage,
        deliverySearchQuery,
        deliveryFromDate,
        deliveryToDate,
        deliveryType,
        statusFilter,
      } = state;
      const { data } = await _get(
        `${API.DRIVER.FETCH_DELIVERY}/${payload}?page=${deliveryPage}&statusFilter=${statusFilter}&search=${deliverySearchQuery}&fromDate=${deliveryFromDate}&toDate=${deliveryToDate}&dateType=${deliveryType}&limit=5`
      );
      commit("setDeliveries", data);
    },
    setDeliveriesPage({ commit }, page) {
      commit("setDeliveriesPage", page);
    },
    setDeliveriesSearchQuery({ commit }, payload) {
      commit("setDeliveriesSearchQuery", payload);
    },
    setDeliveriesDateRange({ commit }, payload) {
      commit("setDeliveriesDateRange", payload);
    },
    setDeliveriesFilterValues({ commit }, payload) {
      commit("setDeliveriesFilterValues", payload);
    },
    async exportDeliveries({ state, rootState }, payload) {
      const {
        deliverySearchQuery,
        deliveryFromDate,
        deliveryToDate,
        deliveryType,
        statusFilter,
      } = state;
      submitPostForm(
        `${SERVER_URL}/logistics/drivers/export/requests?companyID=${rootState.logistics.authentication.loggedUser.company_id}&statusFilter=${statusFilter}&search=${deliverySearchQuery}&fromDate=${deliveryFromDate}&toDate=${deliveryToDate}&dateType=${deliveryType}&driverID=${payload}`,
        "token",
        Vue.$cookies.get(SHIOK_LOGISTICS_TOKEN)
      );
    },
  },
};
