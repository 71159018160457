import { _get } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    logs: {},
  },
  getters: {
    logs: ({ logs }) => logs,
  },
  mutations: {
    setLogs(storeState, {order_log}) {
      let logObj = {}
      order_log.map(log=>{
        logObj = { ...logObj, [log.status] : log}
      })
      storeState.logs = {items: logObj, count: Object.keys(logObj).length};
    },
  },
  actions: {
    async getOrderLogs({ commit }, payload) {
      const { data } = await _get(`${API.LOGS.FETCH}/${payload.tracking_id}`)
      commit("setLogs", data);
    },
  },
};
