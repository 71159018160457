<template>
  <div>
    <v-progress-circular
      :size="size"
      :width="width"
      color="#FF003F"
      indeterminate
    >
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      default: '70',
    },
    width: {
      default: '3',
    },
  },
}
</script>

<style lang="scss">
</style>
