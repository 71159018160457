import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    adminLogistics: [],
    adminLogisticCount: 0,
    searchQuery: "",
    page: 1,
    limit: 10,
    fromDate: "",
    toDate: "",
    type: "",
  },
  getters: {
    adminLogisticCount: ({ adminLogisticCount }) => adminLogisticCount,
    adminLogistics: ({ adminLogistics }) => adminLogistics,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
  },
  mutations: {
    setLogistics(state, payload) {
      state.adminLogisticCount = payload.companiesCount;
      state.adminLogistics = payload.companies.map((obj) => {
        obj.driverSelected = false;
        obj.userSelected = false;
        obj.deliverySelected = false;
        obj.selected = false;
        return obj;
      });
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async addLogistic(storeState, payload) {
      await _post(API.LOGISTIC.CREATE, payload);
    },
    async updateLogistic(storeState, payload) {
      await _put(`${API.LOGISTIC.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteLogistic(storeState, payload) {
      await _delete(`${API.LOGISTIC.DELETE}/${payload.id}`);
    },
    async setActiveLogistic(storeState, payload) {
      await _put(`${API.LOGISTIC.ACTIVE}/${payload.id}`);
    },
    async getLogistics({ commit, state }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      const { data } = await _get(
        `${API.LOGISTIC.FETCH}?page=${page}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setLogistics", data);
    },
    async getLogisticById() {
      await _get(`${API.LOGISTIC.FETCH}/1`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportLogistics({ state }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.LOGISTIC.FETCH}/export/logistics?page=${page}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        "token",
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      );
    },
  },
};
