import { _delete, _get, _post, _put } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    drivers: [],
    searchQuery: "",
    logisticDriverPage: 1,
    totalDrivers: 0,
    driverFromDate: "",
    driverToDate: "",
    type: "",
  },
  getters: {
    drivers: ({ drivers }) => drivers,
    totalDrivers: ({ totalDrivers }) => totalDrivers,
    logisticDriverPage: ({ logisticDriverPage }) => logisticDriverPage,
    driverFromDate: ({ driverFromDate }) => driverFromDate,
    driverToDate: ({ driverToDate }) => driverToDate,
  },
  mutations: {
    setDrivers(storeState, { drivers, driversCount }) {
      storeState.drivers = drivers;
      storeState.totalDrivers = driversCount;
    },
    setPage(storeState, payload) {
      storeState.logisticDriverPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.driverFromDate = dateRange ? dateRange.fromDate : "";
      storeState.driverToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getDrivers({ commit, state }, payload) {
      const { logisticDriverPage, searchQuery, driverFromDate, driverToDate, type } = state;
      const { data } = await _get(
        `${API.LOGISTIC.DRIVER.FETCH}/getAll/${payload.id}?page=${logisticDriverPage}&search=${searchQuery}&limit=5&fromDate=${driverFromDate}&toDate=${driverToDate}&dateType=${type}`
      );
      commit("setDrivers", data);
    },
    async addDriver(storeState, payload) {
      await _post(API.LOGISTIC.DRIVER.CREATE, payload);
    },
    async updateDriver(storeState, payload) {
      await _put(`${API.LOGISTIC.DRIVER.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteDriver(storeState, payload) {
      await _delete(`${API.LOGISTIC.DRIVER.DELETE}/${payload.id}`);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
  },
};
