import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_MERCHANT_TOKEN } from "../config/constants";
import { submitPostForm } from "../../../services/ReusableServices/form-post-dom";
import { SERVER_URL } from "../../../../constants";

export default {
  namespaced: true,

  state: {
    orders: [],
    logistics: { fav: [], all: [] },
    allLogistics: [],
    uomDataSet: [],
    searchQuery: "",
    page: 1,
    limit: 10,
    totalOrders: 0,
    fromDate: "",
    toDate: "",
    type: "",
    logisticFilter: "",
    productNameFilter: "",
    statusFilter: "",
    productCategoryFilter: "",
    isOrderFilter: false,
  },
  getters: {
    orders: ({ orders }) => orders,
    logistics: ({ logistics }) => logistics,
    totalOrders: ({ totalOrders }) => totalOrders,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    uomDataSet: ({ uomDataSet }) => uomDataSet,
    allLogistics: ({ allLogistics }) => allLogistics,
    isOrderFilter: ({ isOrderFilter }) => isOrderFilter,
  },
  mutations: {
    setOrders(storeState, { orders, ordersCount }) {
      storeState.orders = orders;
      storeState.totalOrders = ordersCount;
    },
    setFavLogistics(
      storeState,
      { logisticDetails, logisticDetailsNotFourite }
    ) {
      logisticDetails.map((item) => {
        item.isFav = true;
      });
      storeState.logistics = {
        fav: logisticDetails,
        all: logisticDetailsNotFourite,
      };
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setFilterValues(storeState, payload) {
      if (payload !== null) {
        const {
          logisticFilter,
          productNameFilter,
          statusFilter,
          productCategoryFilter,
          dateRange,
          type,
        } = payload;
        storeState.logisticFilter = logisticFilter ? logisticFilter : "";
        storeState.productNameFilter = productNameFilter
          ? productNameFilter
          : "";
        storeState.statusFilter = statusFilter ? statusFilter : "";
        storeState.productCategoryFilter = productCategoryFilter
          ? productCategoryFilter
          : "";
        storeState.fromDate = dateRange ? dateRange.fromDate : "";
        storeState.toDate = dateRange ? dateRange.toDate : "";
        storeState.type = type ? type : "";
        storeState.isOrderFilter = true;
      } else {
        storeState.logisticFilter = "";
        storeState.productNameFilter = "";
        storeState.statusFilter = "";
        storeState.productCategoryFilter = "";
        storeState.fromDate = "";
        storeState.toDate = "";
        storeState.type = "";
        storeState.isOrderFilter = false;
      }
    },
    setProductUOM(storeState, payload) {
      storeState.uomDataSet = payload.map((obj) => {
        return obj.uom;
      });
    },
    setLogistics(storeState, payload) {
      const { companies } = payload;
      storeState.allLogistics = companies;
    },
  },
  actions: {
    async getOrders({ commit, state, rootState }) {
      const {
        page,
        limit,
        searchQuery,
        fromDate,
        toDate,
        type,
        logisticFilter,
        productNameFilter,
        statusFilter,
        productCategoryFilter,
      } = state;
      const { data } = await _get(
        `${API.ORDERS.FETCH}?&companyID=${rootState.merchant.authentication.loggedUser.company_id}&page=${page}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&productNameFilter=${productNameFilter}&statusFilter=${statusFilter}&productCategoryFilter=${productCategoryFilter}&logisticFilter=${logisticFilter}`
      );
      commit("setOrders", data);
    },
    async addOrder(storeState, payload) {
      await _post(API.ORDERS.CREATE, payload);
    },
    async updateOrder(storeState, payload) {
      await _put(`${API.ORDERS.UPDATE}/${payload.id}`, payload);
    },
    async deleteOrder(storeState, payload) {
      await _delete(`${API.ORDERS.DELETE}/${payload.id}`);
    },
    async updateBillingAddress(storeState, payload) {
      await _put(`${API.ORDERS.UPDATE_BILLING}/${payload.id}`, payload.data);
    },
    async getLogistics({ commit }) {
      const { data } = await _get(`${API.ORDERS.FETCH_LOGISTIC}`);
      commit("setLogistics", data);
    },
    async getFavoriteLogistics({ commit }) {
      const { data } = await _get(`${API.ORDERS.FETCH_FAV_LOGISTIC}`);
      commit("setFavLogistics", data);
    },
    async setFavoriteLogistics(storeState, payload) {
      await _put(`${API.ORDERS.SET_FAV_LOGISTIC}`, {
        logistic_id: payload.id,
      });
    },
    async assignLogistics(storeState, payload) {
      await _put(`${API.ORDERS.ASSIGN_LOGISTIC}/${payload.id}`, payload);
    },
    async reAssignLogistics(storeState, payload) {
      await _put(`${API.ORDERS.REASSIGN_LOGISTIC}/${payload.id}`, payload);
    },
    async getProductUOM({ commit, rootState }) {
      const { data } = await _get(
        `${API.ORDERS.UOM_FETCH}?companyID=${rootState.merchant.authentication.loggedUser.company_id}`
      );
      commit("setProductUOM", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setFilterValues({ commit }, payload) {
      commit("setFilterValues", payload);
    },
    async exportOrders({ state, rootState }) {
      const {
        page,
        limit,
        searchQuery,
        fromDate,
        toDate,
        type,
        logisticFilter,
        productNameFilter,
        statusFilter,
        productCategoryFilter,
      } = state;
      submitPostForm(
        `${SERVER_URL}/merchant${API.ORDERS.FETCH}/export/orders?page=${page}&companyID=${rootState.merchant.authentication.loggedUser.company_id}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}&productNameFilter=${productNameFilter}&statusFilter=${statusFilter}&productCategoryFilter=${productCategoryFilter}&logisticFilter=${logisticFilter}`,
        "token",
        Vue.$cookies.get(SHIOK_MERCHANT_TOKEN)
      );
    },
  },
};
