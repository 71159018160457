import Vue from "vue";
import { _get } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from '../../../services/ReusableServices/form-post-dom'
import { SERVER_URL } from '../../../../constants'


export default {
  namespaced: true,

  state: {
    orders: [],
    searchQuery: "",
    ordersPage: 1,
    totalOrders: 0,
    ordersFromDate: "",
    ordersToDate: "",
    type: "",
    isCustomerFilter: false,
    statusFilter: "",
    merchantFilter: "",
    merchantDropdown: [],
    logisticsDropdown: [],
  },
  getters: {
    orders: ({ orders }) => orders,
    totalOrders: ({ totalOrders }) => totalOrders,
    ordersPage: ({ ordersPage }) => ordersPage,
    ordersFromDate: ({ ordersFromDate }) => ordersFromDate,
    ordersToDate: ({ ordersToDate }) => ordersToDate,
    isCustomerFilter: ({ isCustomerFilter }) => isCustomerFilter,
    statusFilter: ({ statusFilter }) => statusFilter,
    merchantFilter: ({ merchantFilter }) => merchantFilter,
    merchantDropdown: ({merchantDropdown}) => merchantDropdown,
    logisticsDropdown: ({logisticsDropdown}) => logisticsDropdown,
  },
  mutations: {
    setOrders(storeState, { orders, ordersCount }) {
      storeState.orders = orders;
      storeState.totalOrders = ordersCount;
    },
    setPage(storeState, payload) {
      storeState.ordersPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.ordersFromDate = dateRange ? dateRange.fromDate : "";
      storeState.ordersToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setFilterValues(storeState, payload) {
      if (payload !== null) {
        const {
          statusFilter,
          merchantFilter,
          logisticsFilter,
          dateRange,
          type,
        } = payload;

        storeState.statusFilter = statusFilter ? statusFilter : "";
        storeState.merchantFilter = merchantFilter ? merchantFilter : "";
        storeState.logisticsFilter = logisticsFilter ? logisticsFilter : "";
        storeState.ordersFromDate = dateRange ? dateRange.fromDate : "";
        storeState.ordersToDate = dateRange ? dateRange.toDate : "";
        storeState.type = type ? type : "";
        storeState.isCustomerFilter = true;
      } else {
        storeState.statusFilter = "";
        storeState.merchantFilter = "";
        storeState.logisticsFilter = "";
        storeState.ordersFromDate = "";
        storeState.ordersToDate = "";
        storeState.type = "";
        storeState.isCustomerFilter = false;
      }
    },
    setMerchantDropdown(storeState, company) {
      storeState.merchantDropdown = company.map((obj) => {
        obj.name = obj.company_name;
        return obj;
      });
    },
    setLogisticsDropdown(storeState, company) {
      storeState.logisticsDropdown = company.map((obj) => {
        obj.name = obj.company_name;
        return obj;
      });
    },
  },
  actions: {
    async getOrders({ commit, state }, payload) {
      const {
        ordersPage,
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
        statusFilter,
        merchantFilter,
        logisticsFilter
      } = state;
      const { data } = await _get(
        `${API.CUSTOMERS.ORDERS.FETCH}/?customerID=${payload.id}&page=${ordersPage}&search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&dateType=${type}&statusFilter=${statusFilter}&merchantID=${merchantFilter}&logisticID=${logisticsFilter}&limit=5`
      );
      commit("setOrders", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    setFilterValues({ commit }, payload) {
      commit("setFilterValues", payload);
    },
    async getMerchantDropdown({ commit }, payload) {
      const { data } = await _get(`${API.CUSTOMERS.ORDERS.FETCH_MERCHANT_DROPDOWN}/${payload}`);
      commit("setMerchantDropdown", data);
    },
    async getLogisticsDropdown({ commit }, payload) {
      const { data } = await _get(`${API.CUSTOMERS.ORDERS.FETCH_LOGISTIC_DROPDOWN}/${payload}`);
      commit("setLogisticsDropdown", data);
    },
    async exportCustomerOrders({ state }, payload) {
      const {
        searchQuery,
        ordersFromDate,
        ordersToDate,
        type,
        statusFilter,
        merchantFilter,
        logisticsFilter,
      } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.CUSTOMERS.FETCH}/export/customer-orders?customerID=${payload.id}&search=${searchQuery}&fromDate=${ordersFromDate}&toDate=${ordersToDate}&dateType=${type}&statusFilter=${statusFilter}&merchantID=${merchantFilter}&logisticID=${logisticsFilter}`,
        'token',
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      )
    },
  },
};
