import { _delete, _get, _post, _put } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    logisticUsers: [],
    logisticUserRoles: [],
    searchQuery: "",
    logisticUserPage: 1,
    totalLogisticUsers: 0,
    logisticFromDate: "",
    logisticToDate: "",
    type: "",
  },
  getters: {
    logisticUsers: ({ logisticUsers }) => logisticUsers,
    totalLogisticUsers: ({ totalLogisticUsers }) => totalLogisticUsers,
    logisticUserPage: ({ logisticUserPage }) => logisticUserPage,
    logisticUserRoles: ({ logisticUserRoles }) => logisticUserRoles,
    logisticFromDate: ({ logisticFromDate }) => logisticFromDate,
    logisticToDate: ({ logisticToDate }) => logisticToDate,
  },
  mutations: {
    setUsers(storeState, { users, usersCount }) {
      storeState.logisticUsers = users;
      storeState.totalLogisticUsers = usersCount;
    },
    setPage(storeState, payload) {
      storeState.logisticUserPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setLogisticRoles(storeState, payload) {
      storeState.logisticUserRoles = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.logisticFromDate = dateRange ? dateRange.fromDate : "";
      storeState.logisticToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getUsers({ commit, state }, payload) {
      const { logisticUserPage, searchQuery, logisticFromDate, logisticToDate, type } = state;
      const { data } = await _get(
        `${API.LOGISTIC.USERS.FETCH}/getAll/${payload.id}?page=${logisticUserPage}&search=${searchQuery}&limit=5&fromDate=${logisticFromDate}&toDate=${logisticToDate}&dateType=${type}`
      );
      commit("setUsers", data);
    },
    async addUser(storeState, payload) {
      await _post(API.LOGISTIC.USERS.CREATE, payload);
    },
    async updateUser(storeState, payload) {
      await _put(`${API.LOGISTIC.USERS.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteUser(storeState, payload) {
      await _delete(`${API.LOGISTIC.USERS.DELETE}/${payload.id}`);
    },
    async setLogisticActiveUser(storeState, payload) {
      await _put(`${API.LOGISTIC.USERS.ACTIVE}/${payload.id}`);
    },
    async getLogisticUserRoles({ commit }, payload) {
      const { data } = await _get(
        `${API.LOGISTIC.ROLE.FETCH}/all/?companyID=${payload.id}`
      );
      commit("setLogisticRoles", data.roles);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
  },
};
