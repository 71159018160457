<template>
  <div class="display-flex flex-col  exact-center">
    <div class="image-upload-container position-relative exact-center">
      <v-avatar
        class="profile-pic"
        :size="size"
        :style="{
          background: getColor({ name: name }),
          cursor: clickable ? 'pointer' : 'auto',
          'border-radius': radius ? `${radius}px` : '12px',
        }"
      >
        <img v-if="url && url !== ''" :src="url" :alt="name" />
        <span v-else-if="!url && name">{{
          name.toUpperCase() | filterName
        }}</span>
        <span v-else>+{{ count - 3 }}</span>
      </v-avatar>
      <input
        :disabled="disabled"
        type="file"
        ref="image"
        @change="onFilePicked"
      />
      <div v-if="!editDisable">
        <div
          v-if="url"
          class="icon-container exact-center position-absolute cursor-pointer"
          @click="removePic"
        >
          <img src="@/assets/icons/close.svg" />
        </div>
        <div
          v-else
          class="icon-container exact-center position-absolute cursor-pointer"
          @click="pickFile"
        >
          <img src="@/assets/icons/pencil.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchColor } from "@/services/ReusableServices/fetch-profile-background-color";

export default {
  name: "ProfileImagePicker",
  props: [
    "url",
    "value",
    "disabled",
    "editDisable",
    "size",
    "name",
    "clickable",
    "radius",
    "count",
  ],
  filters: {
    filterName(name) {
      if (!name) {
        return "";
      }
      return name.split("")[0];
    },
  },
  data() {
    return {
      imageFile: {},
      errorMessage: "",
      notifyKey: 0,
    };
  },
  methods: {
    getColor(obj) {
      return fetchColor(obj);
    },
    pickFile() {
      this.$refs.image.click();
    },
    removePic() {
      this.$emit("removePic");
    },
    async onFilePicked({ target }) {
      const { files } = target;
      if (files[0] !== undefined) {
        if (files[0].size < 5242880) {
          this.errorMessage = "";
          this.$emit("fileData", files);
        } else {
          this.isNotification = true;
          this.notifyKey += 1;
          this.errorMessage = "Maximum File Size is 5MB";
        }
      } else {
        this.imageFile = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.image-upload-container {
  .icon-container {
    width: 1.5rem;
    height: 1.5rem;
    background: lightgray;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    right: 0.125rem;
    top: -0.475rem;
  }
  input {
    display: none;
  }
}
</style>
