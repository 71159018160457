<template>
  <select
    name="dial_code"
    v-model="editValue"
    class="dial-code-select"
    @change="onChange"
    :disabled="disabled"
  >
    <option
      v-for="(item, key) in dialCodeDataSet"
      :key="key"
      v-bind:value="item.dial_code"
      >{{ item.code }} (+{{ item.dial_code }})</option
    >
  </select>
</template>

<script>
import { DIAL_CODE } from "../../../constants";
export default {
  name: "DialCode",
  props: ["value", "disabled"],
  data() {
    return {
      dialCodeDataSet: DIAL_CODE,
      editValue: "",
      dropdownSize: 1,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    onChange(e) {
      this.dropdownSize = 1;
      this.editValue = e.target.value;
      this.$emit("change", this.editValue);
    },
  },
  created() {
    this.editValue = this.value;
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>
