<template>
  <v-avatar
    class="profile-pic"
    :size="size"
    :style="{
      background: getColor({ name: name }),
      cursor: clickable ? 'pointer' : 'auto',
      'border-radius': radius ? `${radius}px` : '12px',
    }"
    @click="onClick"
  >
    <img v-if="url && url !== ''" :src="url" :alt="name" />
    <span v-else-if="!url && name">{{ name.toUpperCase() | filterName }}</span>
    <span v-else>+{{ count - 3 }}</span>
  </v-avatar>
</template>

<script>
import { fetchColor } from "@/services/ReusableServices/fetch-profile-background-color";

export default {
  name: "ProfileAvatar",
  props: ["url", "name", "size", "count", "clickable", "radius"],
  filters: {
    filterName(name) {
      if (!name) {
        return "";
      }
      return name.split("")[0];
    },
  },
  methods: {
    getColor(obj) {
      return fetchColor(obj);
    },
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.profile-pic {
  box-sizing: border-box;
  cursor: pointer;
  color: #ffffff;
  span {
    font-size: 0.9rem;
    font-weight: 500;
  }
}
</style>
