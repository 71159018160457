<template>
  <div class="display-flex flex-col  exact-center">
    <Notification
      :snackbar="isNotification"
      :message="errorMessage"
      :key="notifyKey"
      @closeAlert="isNotification = false"
    />
    <div class="image-upload-container position-relative exact-center">
      <div class="profile-container exact-center">
        <img v-if="src" :src="src" alt="user" class="avatar" />
        <img v-else :src="require(`@/assets/icons/${placeholder}.svg`)" />
        <input
          :disabled="disabled"
          type="file"
          ref="image"
          @change="onFilePicked"
        />
      </div>
      <div v-if="!editDisable">
        <div
          v-if="src"
          class="icon-container exact-center position-absolute cursor-pointer"
          @click="removePic"
        >
          <img src="@/assets/icons/close.svg" />
        </div>
        <div
          v-else
          class="icon-container exact-center position-absolute cursor-pointer"
          @click="pickFile"
        >
          <img src="@/assets/icons/pencil.svg" />
        </div>
      </div>
    </div>
    <div class="display-flex mt-4 label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: [
    "value",
    "disabled",
    "src",
    "localSrc",
    "placeholder",
    "label",
    "editDisable",
  ],
  data() {
    return {
      imageFile: {},
      errorMessage: "",
      notifyKey: 0,
      isNotification: false,
    };
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    removePic() {
      this.$emit("removePic");
    },
    async onFilePicked({ target }) {
      const { files } = target;
      if (files[0] !== undefined) {
        if (files[0].size < 5242880) {
          this.errorMessage = "";
          this.$emit("fileData", files);
        } else {
          this.isNotification = true;
          this.notifyKey += 1;
          this.errorMessage = "Maximum File Size exceeded!";
        }
      } else {
        this.imageFile = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.label {
  font-size: 12px;
  color: #808080;
}
.image-upload-container {
  width: 11.25rem;
  height: 11.25rem;
  border: 1px dashed $primary-color;
  background: #ff003f0d;
  border-radius: 0.5rem;
  .icon-container {
    width: 2rem;
    height: 2rem;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    right: -0.875rem;
    top: -0.875rem;
  }
  .profile-container {
    width: 10.6rem;
    height: 10.6rem;
    border-radius: 0.5rem;
    overflow: hidden;
    .avatar {
      width: 100%;
      object-fit: cover;
    }
    input {
      display: none;
    }
  }
}
</style>
