import { _get } from "../services/crudService";
import { API } from "../config/constants";
import moment from "moment";

export default {
  namespaced: true,

  state: {
    resourceCounts: {},
    orderDetails: {},
    orderLogs: [],
    deliveryTypeData: {},
    weekOrderData: {},
    orderSummary: {},
    fromDate: "",
    toDate: "",
    orderTrackDetails: {},
  },
  getters: {
    resourceCounts: ({ resourceCounts }) => resourceCounts,
    orderDetails: ({ orderDetails }) => orderDetails,
    orderLogs: ({ orderLogs }) => orderLogs,
    deliveryTypeData: ({ deliveryTypeData }) => deliveryTypeData,
    weekOrderData: ({ weekOrderData }) => weekOrderData,
    orderSummary: ({ orderSummary }) => orderSummary,
    orderTrackDetails: ({ orderTrackDetails }) => orderTrackDetails,
  },
  mutations: {
    setResourceCounts(storeState, payload) {
      storeState.resourceCounts = payload;
    },
    setOrderTrackingData(storeState, payload) {
      let logObj = {};
      payload?.order_log?.map((log) => {
        logObj = { ...logObj, [log.status]: log };
      });
      storeState.orderDetails = payload.order;
      storeState.orderLogs = {
        items: logObj,
        count: Object.keys(logObj).length,
      };
    },
    setOrderTrackingDataEmpty(storeState) {
      storeState.orderDetails = {};
      storeState.orderLogs = {};
    },
    setDeliveryTypeData(storeState, payload) {
      storeState.deliveryTypeData = payload;
    },
    setWeekOrderData(storeState, payload) {
      payload.currentWeekCount.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      payload.lastWeekCount.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      storeState.weekOrderData.current = payload.currentWeekCount.map((obj) => {
        return Number(obj.count);
      });
      storeState.weekOrderData.last = payload.lastWeekCount.map((obj) => {
        return Number(obj.count);
      });
    },
    setOrderSummary(storeState, payload) {
      storeState.orderSummary = payload;
      payload.dateWiseGraph.sort(function(a, b) {
        return new Date(a.date_trunc) - new Date(b.date_trunc);
      });
      storeState.orderSummary.xAxis = payload.dateWiseGraph.map((obj) => {
        return moment(obj.date_trunc).format("DD");
      });
      storeState.orderSummary.yAxis = payload.dateWiseGraph.map((obj) => {
        return Number(obj.count);
      });
    },
    setDate(storeState, { fromDate, toDate }) {
      storeState.fromDate = fromDate;
      storeState.toDate = toDate;
    },
    setOrderTrackDetails(storeState, payload) {
      storeState.orderTrackDetails = payload;
    },
  },
  actions: {
    async getResourceCounts({ commit, rootState }) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_COUNT}?companyID=${rootState.logistics.authentication.loggedUser.company_id}`
      );
      commit("setResourceCounts", data);
    },
    async getOrderTrackingData({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_ORDER_TRACKING}/${payload}`
      );
      commit("setOrderTrackingData", data);
    },
    async getDeliveryTypeData({ commit, state, rootState }) {
      const { fromDate, toDate } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_DELIVERY_TYPE}/?companyID=${rootState.logistics.authentication.loggedUser.company_id}&fromDate=${fromDate}&toDate=${toDate}`
      );
      commit("setDeliveryTypeData", data);
    },
    async getWeekOrderData({ commit }) {
      const { data } = await _get(`${API.DASHBOARD.FETCH_WEEK_ORDER_DATA}`);
      commit("setWeekOrderData", data);
    },
    async getOrderSummary({ commit, state }) {
      const { fromDate, toDate } = state;
      const { data } = await _get(
        `${API.DASHBOARD.FETCH_ORDER_SUMMARY}?fromDate=${fromDate}&toDate=${toDate}`
      );
      commit("setOrderSummary", data);
    },
    setDate({ commit }, payload) {
      commit("setDate", payload);
    },
    async trackOrder({ commit }, payload) {
      const { data } = await _get(
        `${API.DASHBOARD.ORDER_TRACK}?trackingID=${payload}`
      );
      commit("setOrderTrackDetails", data[0]);
    },
    setOrderTrackingDataEmpty({ commit }) {
      commit("setOrderTrackingDataEmpty");
    },
  },
};
