export const API = {
  AUTHENTICATION: {
    FORGOT_PASSWORD: "auth/forgot-password",
    RESET_PASSWORD: "auth/reset-password",
    SIGNIN: "auth/signin",
    CHANGE_PASSWORD: "users/profile/change-password",
  },
  DASHBOARD: {
    FETCH_COUNT: "dashboard/count",
    FETCH_ORDER_TRACKING: "dashboard/order-tracking",
    FETCH_LOGISTICS_REPORT: "dashboard/logistic-reports",
    FETCH_MERCHANT_REPORT: "dashboard/merchant-reports",
    FETCH_MERCHANT_CUSTOMER: "dashboard/merchant-customers",
    FETCH_LOGISTICS_CUSTOMER: "dashboard/logistic-customers",
    FETCH_MONTHLY_REPORT: "dashboard/monthly-count",
    FETCH_DELIVERY_REPORT: "dashboard/todays-delivery",
    ORDER_TRACK: "dashboard/tracking-details",
    EXPORT_REPORT: "/dashboard/export/report",
    EXPORT_MERCHANT: "/dashboard/export/merchant-report",
    EXPORT_LOGISTIC: "/dashboard/export/logistic-report",
  },
  SETTINGS: {
    FETCH: "/roles/all",
    FETCH_BY_ID: "/roles",
    CREATE: "/roles",
    DELETE: "/roles",
    UPDATE: "/roles",
    UPDATE_PERMISSION: "/permissions",
  },
  USERS: {
    FETCH: "/users",
    CREATE: "/users",
    DELETE: "/users",
    ACTIVE: "/users/make-active",
    UPDATE: "/users",
  },
  CUSTOMERS: {
    FETCH: "/customer/users",
    ORDERS: {
      FETCH: "customer/users/get-customer-orders",
      FETCH_MERCHANT_DROPDOWN: "/customer/users/get-customer-merchants",
      FETCH_LOGISTIC_DROPDOWN: "/customer/users/get-customer-logistics",
    },
  },
  MERCHANT: {
    FETCH: "/merchant/company",
    CREATE: "/merchant/company",
    DELETE: "/merchant/company",
    ACTIVE: "/merchant/company/make-active",
    UPDATE: "/merchant/company",
    WAREHOUSE: {
      FETCH: "/merchant/warehouse",
      CREATE: "/merchant/warehouse",
      DELETE: "/merchant/warehouse",
      UPDATE: "/merchant/warehouse",
    },
    USERS: {
      FETCH: "/merchant/user",
      CREATE: "/merchant/user",
      DELETE: "/merchant/user",
      ACTIVE: "/merchant/user/make-active",
      UPDATE: "/merchant/user",
    },
    ORDERS: {
      FETCH: "/merchant/order",
      FETCH_LOGISTIC_DROPDOWN: "/merchant/order/logistic/dropdown"
    },
    ROLE: {
      FETCH: "/merchant/role",
    },
  },
  LOGISTIC: {
    FETCH: "/logistics/company",
    CREATE: "/logistics/company",
    DELETE: "/logistics/company",
    ACTIVE: "/logistics/company/make-active",
    UPDATE: "/logistics/company",
    USERS: {
      FETCH: "/logistics/users",
      CREATE: "/logistics/users",
      DELETE: "/logistics/users",
      ACTIVE: "/logistics/users/make-active",
      UPDATE: "/logistics/users",
    },
    DRIVER: {
      FETCH: "/logistics/drivers",
      CREATE: "/logistics/drivers",
      DELETE: "/logistics/drivers",
      UPDATE: "/logistics/drivers",
    },
    REQUESTS: {
      FETCH: "logistics/request",
      FETCH_MERCHANT_DROPDOWN: "/logistics/request/merchant/dropdown"
    },
    ROLE: {
      FETCH: "/logistics/role",
    },
  },
  PROMOTION: {
    FETCH: "/promotion",
    CREATE: "/promotion",
    DELETE: "/promotion",
    UPDATE: "/promotion",
    FETCH_PRODUCTS: "/promotion/product/dropdown/get-all",
    FETCH_CUSTOMES: "/promotion/customers",
    FETCH_MERCHANT_DROPDOWN: "/promotion/merchant/dropdown",
    FETCH_CUSTOMER_DROPDOWN: "/promotion/merchant-customer/dropdown",
    CUSTOMER_REMOVE: "/promotion/customer/update-remove",
    CUSTOMER_ADD: "/promotion/customer/update-add",
  },
  PRODUCT: {
    CREATE: "/promotion/product",
    CATEGORY: "/promotion/product/dropdown/category/getAll",
    BRAND: "/promotion/product/dropdown/brand/getAll",
  },
};

export const SHIOK_ADMIN_TOKEN = "shiokAdminToken";

export const ROUTES = {
  AUTH: "/auth",
  SIGNIN: "/auth/login/admin",
  DASHBOARD: "/admin/dashboard",
};
