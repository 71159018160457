<template>
  <div class="date-filter-container">
    <v-edit-dialog
      class="large"
      @cancel="resetClicked"
      @save="filterClicked"
      ref="filterModal"
    >
      <div
        class="date-filter-button align-center cursor-pointer justify-space-between width-100"
      >
        <div class="date-title">Date Filter</div>
        <div class="position-relative">
          <img src="@/assets/icons/calendar-darker.svg" />
          <div :key="keyValue ? keyValue : 1" class="filter-marker position-absolute" v-if="isFilter"></div>
        </div>
      </div>
      <template v-slot:input>
        <div class="date-filter-modal">
          <SelectField
            label="Select Type"
            :data-set="typeDataSet"
            :value="type"
            :key="typeKey"
            @change="getFormData($event, 'type')"
          />
          <DateSelectField
            label="Select Date"
            :isDatePicker="true"
            :data-set="dateRangeSet"
            :key="dateKey"
            @change="getFormData($event, 'dateRange')"
          />
        </div>
        <div
          class="display-flex width-100 justify-end align-center pr-3 mb-5 mt-2"
        >
          <Button
            label="Reset"
            type="cancel"
            width="94px"
            @click="resetClicked"
          />
          <Button
            class="ml-2"
            label="Filter"
            type="save"
            width="94px"
            @click="filterClicked"
            :disabled="!type || !dateRange"
          />
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
export default {
  name: "DateFilter",
  props: ["isFilter", "dateFilterType", "keyValue"],
  data() {
    return {
      type: "",
      dateValue: "",
      dateRange: null,
      dateKey: 0,
      typeKey: 10,
      typeDataSet: [],
      dateRangeSet: ["Today", "This Week", "This Month"],
    };
  },
  methods: {
    getFormData(data, field) {
      if (field === "type") {
        this.type = data.value;
      }
      if (field === "dateRange") {
        this.dateRange = data;
      }
    },
    filterClicked() {
      this.$refs["filterModal"].isActive = false;
      this.$emit("filterClicked", {
        type: this.type,
        dateRange: this.dateRange,
      });
    },
    resetClicked() {
      this.$refs["filterModal"].isActive = false;
      this.dateRange = null;
      this.type = "";
      this.dateKey += 1;
      this.typeKey += 1;
      this.$emit("resetClicked");
    },
  },
  created() {
    this.typeDataSet = this.dateFilterType;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common";
.date-filter-container {
  border: 1px solid #e9e9e9;
  .date-filter-button {
    width: 8rem;
    padding: 0.563rem 0.938rem;
    background: $white-color;
    border-radius: 4px;
    height: 2.25rem;
    .date-title {
      color: $secondary-color;
      font-weight: 600;
      font-size: 0.75rem;
    }
    .filter-marker {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      background: $primary-color;
      top: -3px;
      right: -4px;
    }
  }
}
.date-filter-modal {
  height: 11.688rem;
  padding: 0.938rem 1.063rem;
}
</style>
