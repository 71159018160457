import { _delete, _get, _post, _put } from "../services/crudService";
import { API } from "../config/constants";

export default {
  namespaced: true,

  state: {
    merchantUsers: [],
    merchantUserRoles: [],
    searchQuery: "",
    merchantUserPage: 1,
    totalMerchantUsers: 0,
    userFromDate: "",
    userToDate: "",
    type: "",
  },
  getters: {
    merchantUsers: ({ merchantUsers }) => merchantUsers,
    totalMerchantUsers: ({ totalMerchantUsers }) => totalMerchantUsers,
    merchantUserPage: ({ merchantUserPage }) => merchantUserPage,
    merchantUserRoles: ({ merchantUserRoles }) => merchantUserRoles,
    userFromDate: ({ userFromDate }) => userFromDate,
    userToDate: ({ userToDate }) => userToDate,
  },
  mutations: {
    setUsers(storeState, { users, usersCount }) {
      storeState.merchantUsers = users;
      storeState.totalMerchantUsers = usersCount;
    },
    setPage(storeState, payload) {
      storeState.merchantUserPage = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setMerchantRoles(storeState, payload) {
      storeState.merchantUserRoles = payload;
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.userFromDate = dateRange ? dateRange.fromDate : "";
      storeState.userToDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
  },
  actions: {
    async getUsers({ commit, state }, payload) {
      const {
        merchantUserPage,
        searchQuery,
        userFromDate,
        userToDate,
        type,
      } = state;
      const { data } = await _get(
        `${API.MERCHANT.USERS.FETCH}/getAll/${payload.id}?page=${merchantUserPage}&search=${searchQuery}&fromDate=${userFromDate}&toDate=${userToDate}&dateType=${type}&limit=5`
      );
      commit("setUsers", data);
    },
    async addUser(storeState, payload) {
      await _post(API.MERCHANT.USERS.CREATE, payload);
    },
    async updateUser(storeState, payload) {
      await _put(`${API.MERCHANT.USERS.UPDATE}/${payload.id}`, payload.data);
    },
    async deleteUser(storeState, payload) {
      await _delete(`${API.MERCHANT.USERS.DELETE}/${payload.id}`);
    },
    async setMerchantActiveUser(storeState, payload) {
      await _put(`${API.MERCHANT.USERS.ACTIVE}/${payload.id}`);
    },
    async getMerchantUserRoles({ commit }, payload) {
      const { data } = await _get(
        `${API.MERCHANT.ROLE.FETCH}/all/?companyID=${payload.id}`
      );
      commit("setMerchantRoles", data.roles);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
  },
};
