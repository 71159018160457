import { API } from "../config/constants";
import { _get } from "../services/crudService";

export default {
    namespaced: true,

    state: {
        assignments: [],
    },
    getters: {
        assignments: ({ assignments }) => assignments
    },
    mutations: {
        setAssignments(storeState, { assignments }) {
            storeState.assignments = assignments;
          },
    },
    actions: {
        async getAssignments({ commit }, payload) {
            const { data } = await _get(
                `${API.ASSIGNMENTS.FETCH}?requestID=${payload.id}`
            );
            commit('setAssignments', data)
        }
    }
}