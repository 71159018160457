<template>
  <div class="common-toolbar-outer justify-space-between align-center pl-6 pr-6">
    <div class="display-flex align-center pl-6 ml-5">
      <div v-if="title" class="toolbar-title">{{ title }}</div>
      <div v-if="isSearch" class="ml-4"><SearchInput @change="onSearch" /></div>
    </div>
    <div class="display-flex pr-6 mr-6">
      <div class="mr-6">
        <DateFilter
          v-if="showDateFilter"
          :isFilter="isFilter"
          @filterClicked="filterClicked"
          @resetClicked="resetClicked"
          :dateFilterType="dateFilterType"
        />
      </div>
      <!-- Slot for Add on Filters -->
      <slot></slot>
      <div v-if="secondaryButtonLabel">
        <Button
          @click="onSecondaryButtonClick"
          :label="secondaryButtonLabel"
          type="secondary"
          width="137px"
        />
      </div>
      <div v-if="primaryButtonLabel" class="ml-4">
        <Button
          @click="onPrimaryButtonClick"
          :label="primaryButtonLabel"
          :loading="primaryButtonLoading"
          :disabled="primaryButtonLoading"
          type="primary"
          width="145px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonToolbar",
  props: [
    "title",
    "isSearch",
    "secondaryButtonLabel",
    "primaryButtonLabel",
    "showDatePickers",
    "isFilter",
    "dateFilterType",
    "primaryButtonLoading",
    "showDateFilter",
  ],
  data() {
    return {};
  },
  methods: {
    onSearch(value) {
      this.$emit("search", value);
    },
    onPrimaryButtonClick() {
      this.$emit("primaryButtonClick");
    },
    onSecondaryButtonClick() {
      this.$emit("secondaryButtonClick");
    },
    filterClicked(value) {
      this.$emit("filterClicked", value);
    },
    resetClicked() {
      this.$emit("resetClicked");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.common-toolbar-outer {
  height: 87px;
  background: #f6f6f7;
  width: 100%;
}
.toolbar-title {
  color: #4a5571;
  font-size: 16px;
  font-weight: bold;
}

::v-deep .date-picker-width > .v-input > .v-input__control {
  height: 36px;
}
</style>
