import { AuthGuard } from '../services/Authentication/authGuard';

const MerchantComponent = resolve => {
    require.ensure(['../'], () => {
        resolve(require('../'));
    }, 'merchant');
}

const DashboardComponent = resolve => {
    require.ensure(['../Dashboard'], () => {
        resolve(require('../Dashboard'));
    }, 'merchant');
}

const MerchantOrderComponent = resolve => {
    require.ensure(['../Orders'], () => {
        resolve(require('../Orders'));
    }, 'merchant');
}

const MerchantWarehouseComponent = resolve => {
    require.ensure(['../Warehouse'], () => {
        resolve(require('../Warehouse'));
    }, 'merchant');
}

const MerchantCustomerComponent = resolve => {
    require.ensure(['../Customers'], () => {
        resolve(require('../Customers'));
    }, 'merchant');
}

const MerchantProductComponent = resolve => {
    require.ensure(['../Products'], () => {
        resolve(require('../Products'));
    }, 'merchant');
}

const MerchantUsersComponent = resolve => {
    require.ensure(['../Users'], () => {
        resolve(require('../Users'));
    }, 'merchant');
}

const MerchantSettingsComponent = resolve => {
    require.ensure(['../Settings'], () => {
        resolve(require('../Settings'));
    }, 'merchant');
}

export const MerchantRoutes = [
    {
        path: "/merchant",
        component: MerchantComponent,
        beforeEnter: AuthGuard,
        children: [
            {
                path: '/',
                name: 'Merchant Dashboard',
                component: DashboardComponent,
            },
            {
                path: 'dashboard',
                name: 'MerchantDashboard',
                component: DashboardComponent,
            },
            {
                path: 'orders',
                name: 'Merchant Order',
                component: MerchantOrderComponent,
            },
            {
                path: 'warehouse',
                name: 'MerchantWarehouse',
                component: MerchantWarehouseComponent,
            },
            {
                path: 'customers',
                name: 'Merchant Customers',
                component: MerchantCustomerComponent,
            },
            {
                path: 'products',
                name: 'Merchant Products',
                component: MerchantProductComponent,
            },
            {
                path: 'users',
                name: 'Merchant Users',
                component: MerchantUsersComponent,
            },
            {
                path: 'settings',
                name: 'Merchant Settings',
                component: MerchantSettingsComponent,
            },
        ],
    }
];


export default MerchantRoutes;