import { AuthGuard } from "../services/authguard";

const AuthComponent = (resolve) => {
  require.ensure(["../"], () => {
    resolve(require("../"));
  });
};

const LoginComponent = (resolve) => {
  require.ensure(["../login"], () => {
    resolve(require("../login"));
  });
};

const VerifyEmailComponent = (resolve) => {
  require.ensure(["../ForgotPassword/VerifyEmail"], () => {
    resolve(require("../ForgotPassword/VerifyEmail"));
  });
};

const ResetPasswordComponent = (resolve) => {
  require.ensure(["../ForgotPassword/ResetPassword"], () => {
    resolve(require("../ForgotPassword/ResetPassword"));
  });
};

export const AuthRoutes = [
  {
    path: "/auth",
    component: AuthComponent,
    children: [
      {
        path: "login/:type",
        name: "Login",
        beforeEnter: AuthGuard,

        component: LoginComponent,
      },
      {
        path: "verify-email/:type",
        name: "VerifyEmail",
        beforeEnter: AuthGuard,

        component: VerifyEmailComponent,
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: ResetPasswordComponent,
      },
    ],
  },
];

export default AuthRoutes;
