<template>
  <div>
    <v-textarea
      :label="label"
      auto-grow
      filled
      v-model="editValue"
      :rows="rows"
      row-height="15"
      :value="value"
      :error="error"
      :class="{ textAreaError: error, required: required }"
      @input="onchange"
      :disabled="disabled"
      @blur="doActionAtBlur(editValue)"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: ["rows", "label", "required", "value", "error", "disabled"],
  components: {},
  data() {
    return {
      editValue: "",
    };
  },
  methods: {
    onchange(value) {
      this.$emit("change", value);
    },
    doActionAtBlur(editValue) {
      this.$emit("textAreaBlur", editValue);
    },
  },
  watch: {
    editValue() {
      this.$emit("TextAreaChange", this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

::v-deep
  .theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid #dce2f0;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #dce2f0 !important;
}
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}

::v-deep
  .textAreaError.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: $white-color !important;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 1px;
}
::v-deep
  .textAreaError.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red !important;
}

::v-deep
  .theme--light.v-text-field--filled.required
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label::after {
  content: " *";
  font-size: 14px;
  color: #ff0000;
}

::v-deep .v-text-field input {
    margin-left: 6px !important;
}
</style>
