import Vue from "vue";
import { _delete, _get, _post, _put } from "../services/crudService";
import { API, SHIOK_ADMIN_TOKEN } from "../config/constants";
import { submitPostForm } from '../../../services/ReusableServices/form-post-dom'
import { SERVER_URL } from '../../../../constants'

export default {
  namespaced: true,

  state: {
    promotions: [],
    promotionsCount: 0,
    searchQuery: "",
    page: 1,
    limit: 10,
    promotionProducts: [],
    promotionProductsCount: 0,
    promotionCustomers: [],
    customersData: [],
    fromDate: "",
    toDate: "",
    type: "",
    productLimit: 10,
    productSearchQuery: '',
    productPage: 1,
    customerLimit: 5,
    customerSearchQuery: '',
    customerPage: 1,
    promotionCustomersCount: 0,
    promotionMerchant: [],
  },
  getters: {
    promotionsCount: ({ promotionsCount }) => promotionsCount,
    promotions: ({ promotions }) => promotions,
    page: ({ page }) => page,
    limit: ({ limit }) => limit,
    promotionProducts: ({ promotionProducts }) => promotionProducts,
    promotionCustomers: ({ promotionCustomers }) => promotionCustomers,
    customersData: ({ customersData }) => customersData,
    fromDate: ({ fromDate }) => fromDate,
    toDate: ({ toDate }) => toDate,
    productLimit: ({ productLimit }) => productLimit,
    productSearchQuery: ({ productSearchQuery }) => productSearchQuery,
    productPage: ({ productPage }) => productPage,
    promotionProductsCount: ({ promotionProductsCount }) => promotionProductsCount,
    customerLimit: ({ customerLimit }) => customerLimit,
    customerSearchQuery: ({ customerSearchQuery }) => customerSearchQuery,
    customerPage: ({ customerPage }) => customerPage,
    promotionCustomersCount: ({ promotionCustomersCount }) => promotionCustomersCount,
    promotionMerchant: ({ promotionMerchant }) => promotionMerchant,
  },
  mutations: {
    setPromotions(state, payload) {
      state.promotionsCount = payload.promotionsCount;
      state.promotions = payload.promotions.map((obj) => {
        obj.selected = false;
        return obj;
      });
    },
    setPage(storeState, payload) {
      storeState.page = payload;
    },
    setLimit(storeState, payload) {
      storeState.limit = payload;
    },
    setSearchQuery(storeState, payload) {
      storeState.searchQuery = payload;
    },
    setPromotionProducts(storeState, payload) {
      storeState.promotionProducts = payload.products.map((obj) => {
        obj.name = obj.product_name;
        return obj;
      });
      storeState.promotionProductsCount = payload.productsCount
    },
    setPromotionCustomers(storeState, users) {
      storeState.promotionCustomers = users.map((obj) => {
        obj.name = obj.user_first_name;
        return obj;
      });
    },
    setPromotionMerchant(storeState, company) {
      storeState.promotionMerchant = company.map((obj) => {
        obj.name = obj.company_name;
        return obj;
      });
    },
    setCustomersData(storeState, { customerDetails }) {
      storeState.customersData = customerDetails.map((obj) => {
        obj.name = obj.user_first_name;
        return obj;
      });
    },
    setDateRange(storeState, { type, dateRange }) {
      storeState.fromDate = dateRange ? dateRange.fromDate : "";
      storeState.toDate = dateRange ? dateRange.toDate : "";
      storeState.type = type ? type : "";
    },
    setProductLimit(storeState, payload) {
      storeState.productLimit = payload
    },
    setProductSearchQuery(storeState, payload) {
      storeState.productSearchQuery = payload
    },
    setProductPage(storeState, payload) {
      storeState.productPage = payload
    },
    setCustomerLimit(storeState, payload) {
      storeState.customerLimit = payload
    },
    setCustomerSearchQuery(storeState, payload) {
      storeState.customerSearchQuery = payload
    },
    setCustomerPage(storeState, payload) {
      storeState.customerPage = payload
    }
  },
  actions: {
    async addPromotions(storeState, payload) {
      await _post(API.PROMOTION.CREATE, payload);
    },
    async updatePromotions(storeState, payload) {
      await _put(`${API.PROMOTION.UPDATE}/${payload.id}`, payload.data);
    },
    async deletePromotions(storeState, payload) {
      await _delete(`${API.PROMOTION.DELETE}/${payload.id}`);
    },
    async getPromotions({ commit, state }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      const { data } = await _get(
        `${API.PROMOTION.FETCH}?page=${page}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`
      );
      commit("setPromotions", data);
    },
    async getPromotionMerchants({ commit }) {
      const { data } = await _get(`${API.PROMOTION.FETCH_MERCHANT_DROPDOWN}`);
      commit("setPromotionMerchant", data);
    },
    async getPromotionCustomers({ commit }, payload) {
      const { data } = await _get(`${API.PROMOTION.FETCH_CUSTOMER_DROPDOWN}/${payload}`);
      commit("setPromotionCustomers", data);
    },
    async fetchPromotionProducts({ commit }, payload) {
      const { data } = await _get(`${API.PROMOTION.FETCH_PRODUCTS}?companyID=${payload}`);
      commit("setPromotionProducts", data);
    },
    setPage({ commit }, payload) {
      commit("setPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("setLimit", payload);
    },
    setSearchQuery({ commit }, payload) {
      commit("setSearchQuery", payload);
    },
    async fetchCustmerDetails({ commit }, payload) {
      const { data } = await _post(`${API.PROMOTION.FETCH_CUSTOMES}/${payload}`);
      commit("setCustomersData", data);
    },
    setDateRange({ commit }, payload) {
      commit("setDateRange", payload);
    },
    async exportPromotions({ state }) {
      const { page, limit, searchQuery, fromDate, toDate, type } = state;
      submitPostForm(
        `${SERVER_URL}/admin${API.PROMOTION.FETCH}/export/promotions?page=${page}&limit=${limit}&search=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&dateType=${type}`,
        'token',
        Vue.$cookies.get(SHIOK_ADMIN_TOKEN)
      )
    },
    setProductLimit({ commit }, payload) {
      commit('setProductLimit', payload)
    },
    setProductSearchQuery({ commit }, payload) {
      commit('setProductSearchQuery', payload)
    },
    setProductPage({ commit }, payload) {
      commit('setProductPage', payload)
    },
    setCustomerLimit({ commit }, payload) {
      commit('setCustomerLimit', payload)
    },
    setCustomerSearchQuery({ commit }, payload) {
      commit('setCustomerSearchQuery', payload)
    },
    setCustomerPage({ commit }, payload) {
      commit('setCustomerPage', payload)
    },
    async removeCustomerFromPromotion(storeState, payload) {
      await _post(`${API.PROMOTION.CUSTOMER_REMOVE}`, payload);
    },
    async addCustomerToPromotion(storeState, payload) {
      await _post(`${API.PROMOTION.CUSTOMER_ADD}`, payload);
    }
  },
};
