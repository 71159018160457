import { AuthGuard } from '../services/Authentication/authGuard';

const AdminComponent = resolve => {
    require.ensure(['../'], () => {
        resolve(require('../'));
    }, 'admin');
}

const DashboardComponent = resolve => {
    require.ensure(['../Dashboard'], () => {
        resolve(require('../Dashboard'));
    }, 'admin');
}

const MerchantComponent = resolve => {
    require.ensure(['../Merchant'], () => {
        resolve(require('../Merchant'));
    }, 'admin');
}

const UserComponent = resolve => {
    require.ensure(['../User'], () => {
        resolve(require('../User'));
    }, 'admin');
}

const LogisticsComponent = resolve => {
    require.ensure(['../Logistics'], () => {
        resolve(require('../Logistics'));
    }, 'admin');
}

const CustomersComponent = resolve => {
    require.ensure(['../Customers'], () => {
        resolve(require('../Customers'));
    }, 'admin');
}

const PromotionsComponent = resolve => {
    require.ensure(['../Promotions'], () => {
        resolve(require('../Promotions'));
    }, 'admin');
}

const SettingsComponent = resolve => {
    require.ensure(['../Settings'], () => {
        resolve(require('../Settings'));
    }, 'admin');
}

export const AdminRoutes = [
    {
        path: "/admin",
        component: AdminComponent,
        beforeEnter: AuthGuard,
        children: [
            {
                path: '/',
                name: 'Admin Dashboard',
                component: DashboardComponent,
            },
            {
                path: 'dashboard',
                name: 'AdminDashboard',
                component: DashboardComponent,
            },
            {
                path: 'merchant',
                name: 'Merchant',
                component: MerchantComponent,
            },
            {
                path: 'users',
                name: 'Users',
                component: UserComponent,
            },
            {
                path: 'logistics',
                name: 'Logistics',
                component: LogisticsComponent,
            },
            {
                path: 'customers',
                name: 'Customers',
                component: CustomersComponent,
            },
            {
                path: 'promotions',
                name: 'Promotions',
                component: PromotionsComponent,
            },
            {
                path: 'settings',
                name: 'Settings',
                component: SettingsComponent,
            },
        ],
    }
];


export default AdminRoutes;