const { _get, _post, _put } = require('../services/crudService')
const { API } = require('../config/constants')

export default {
    namespaced: true,

    state: {
        adminRoles: [],
        adminRolePermision: []
    },
    getters: {
        adminRoles: ({ adminRoles }) => adminRoles,
        adminRolePermision: ({ adminRolePermision }) => adminRolePermision
    },
    mutations: {
        setAdminRoles(state, payload) {
            state.adminRoles = payload.map(item => {
                item.expandSelected = false
                item.actions = true
                return item
            })
        },
        setSelectedAdminRole(state, payload) {
            state.adminRolePermision = payload.permission
        }
    },
    actions: {

        //Actions to fetch, add, delete and update roles in admin portal - settings

        async fetchAdminRoles({ commit }) {
            const { data } = await _get(API.SETTINGS.FETCH)
            commit('setAdminRoles', data.roles)
        },
        async fetchAdminRoleById({ commit }, id) {
            const { data } = await _get(`${API.SETTINGS.FETCH_BY_ID}/${id}`)
            commit('setSelectedAdminRole', data.role)
        },
        async createNewAdminRole(state, payload) {
            await _post(API.SETTINGS.CREATE, payload)
        },
        async updatePermssion(state, payload) {
            await _put(`${API.SETTINGS.UPDATE_PERMISSION}/${payload.id}`, { allow: payload.allow })
        },
        async updateAdminRole(state, payload) {
            await _put(`${API.SETTINGS.UPDATE}/${payload.id}`, {name: payload.name})
        }
    },
}